import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider, Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists, storeDelete } from '@/api/store'
import { newGiftLists, newGiftDelete, newGiftEnd } from '@/api/new-gift'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item;
const { TabPane } = Tabs
class NewGiftLists extends React.Component{
    state = {
        columns:[
            {
                title:'活动名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'活动时间',
                dataIndex:'time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.start_at}-{rowData.end_at}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`orange`}>未开始</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <Tag color={`green`}>进行中</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 3
                                    ?
                                    <Tag color={``}>已结束</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 2
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData)}}>结束活动</Button>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:''
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:'',
        activeStatus:'0',
        tabLists:[
            {
                name:'全部',
                status:'0',
            },
            {
                name:'未开始',
                status:'1',
            },
            {
                name:'进行中',
                status:'2',
            },
            {
                name:'已结束',
                status:'3',
            }
        ]


    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            tableLoading:true
        })
        newGiftLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/new-gift/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    clickTab = val => {
        this.setState({
            activeStatus:val,
        },() => {
            this.fetchData()
        })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/new-gift/add/${rowData.id}`
        })
    }

    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`是否删除该活动？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                newGiftDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleStatus = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        Modal.confirm({
            title:`是否立即结束该活动？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                newGiftEnd(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/index/index?store_id=${row.id}`
        })
        this.ShareModal.show()
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'活动名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索活动名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增新人礼</Button>
                </div>
                <div className={``}>
                    <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                        {
                            this.state.tabLists.map((item,index) => (
                                <TabPane tab={ item.name } key={item.status}></TabPane>
                            ))
                        }
                    </Tabs>
                </div>
                <div className={'content-body '}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(NewGiftLists))
