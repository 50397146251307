import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Tabs, Table, Pagination, Modal, message, Divider, Drawer, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { studentLists,studentAdd,studentDele } from '@/api/student'
import { storeLists } from '@/api/store'
import { gradeApi} from '@/api/educational'
import StudentDetail from './components/studentDetail'
import AddStudentM from "./add"
import StudentUpload from './components/studentUpload'
import moment from 'moment'

const FormItem = Form.Item;
const { RangePicker } = DatePicker
const { TabPane } = Tabs

class StudentLists extends Component {
    state = {
        columns: [

            {
                title: '学员信息',
                dataIndex: '1',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.real_name}
                            <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                        </p>
                    )
                }
            },
            {
                title: '学员手机号',
                dataIndex: '2',
                width: '130px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.parent_mobile}
                        </p>
                    )
                }
            },
            {
                title: '年龄（岁）',
                dataIndex: '3',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.age||"—"}
                        </p>
                    )
                }
            },
            {
                title: '剩余/已用/总课时',
                dataIndex: '4',
                width: '130px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {`${rowData.course_num}/${rowData.use_course_num}/${rowData.total_course_num}`}
                        </p>
                    )
                }
            },
            {
                title: '所属校区',
                dataIndex: '5',
                width: '150px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.store ? rowData.store.name:"—"}
                        </p>
                    )
                }
            },

            {
                title: '课程顾问',
                dataIndex: '6',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.course_adviser?rowData.course_adviser:"—"}
                        </p>
                    )
                }
            },

            {
                title: '备注',
                dataIndex: '7',
                width: '150px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                             {
                                rowData.remark_edit
                                    ?
                                    <Input
                                        type={"text"}
                                        style={{ width: '110px' }}
                                        value={rowData.remark}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.handleField(e, rowData, index) }}
                                        data-name={'remark'}
                                        maxLength={15}
                                    />
                                    :
                                    <span>{rowData.remark ? rowData.remark : `--`}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`remark`} />
                        </div>
                    )
                }
            },

            {
                title: '时间',
                dataIndex: '8',
                width: '230px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>添加时间：{rowData.created_at}</p>
                            <p className={`lh25`}>最近上课时间：{rowData.course_time || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '10',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDetail(rowData) }}>查看详情</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData.id) }}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        searchForm: {
            store_id: 0,
            parent_mobile: "",
            real_name: "",
            start_time: "",
            end_time: "",
            time: ""
        },
        tableData: [],
        page: 1,
        per_page: 10,
        total: 0,
        activeStatus: '0',
        tableLoading: false,
        storeLists: [],
        drawerVisible: false,
        current_id: "",
        isAddStudentM:false,
        gradeLists:[]
    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore()
        this.fetchGrade()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchGrade(){
       
        gradeApi({page:1,per_page:100})
        .then(res => {
            
            if (res.code == 1) {
                let lists = res.data.list.data
              
                this.setState({
                   gradeLists:lists
                })
            }
        })
       
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.course_status = this.state.activeStatus;
        this.setState({
            tableLoading: true
        })
        studentLists(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        searchForm.store_id = 0;

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpload=()=>{
        this.studentUpload.show(res => {
            this.fetchData();
        });
    }

    handleAdd = () => {
        // this.props.history.push("/student/add")
        this.setState({
            isAddStudentM:true
        })
    }

    closeAddSM=()=>{
        this.setState({
            isAddStudentM:false
        })
    }
    handleS=()=>{
        this.setState({
            isAddStudentM:false
        })
        this.fetchData()
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleDetail = row => {
        this.setState({
            drawerVisible: true,
            current_id: row.id
        })
    }
    handleDele=id=>{
        Modal.confirm({
            title:`确定要删除该学员吗？`,
            onOk:() => {
              
                studentDele({id})
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
            }
        })
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible: false
        })
        // this.fetchData();
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'remark':
                lists[index]['remark_edit'] = 1
                break;
        }
        this.setState({
            tableData: lists
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = { ...rowData }
        sendData[name] = val;

        sendData["price"]=Math.abs(sendData["price"])


        studentAdd(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }



    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'学员姓名'} className={'fl'}>
                                {
                                    getFieldDecorator('real_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入学员姓名' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'学员手机号'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('parent_mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入学员手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'所属校区'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'添加时间'} className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>


                        </div>
                        <div className={`clearfix`}>

                            <FormItem className={'fl mgl70'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增学员</Button>
                    <Button type={'default'} onClick={() => { this.handleUpload() }} icon={'upload'} className={`mgl10`}>学员导入</Button>
                </div>
                <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={0}></TabPane>
                    <TabPane tab="课程进行中" key={2}></TabPane>
                    <TabPane tab="课程已结束" key={3}></TabPane>
                    <TabPane tab="未购买课程" key={1}></TabPane>
                </Tabs>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`学员详情`}
                            closable={true}
                            width={`88%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={this.handleDrawerClose}
                            headerStyle={{ borderBottom: '0px' }}
                            bodyStyle={{ padding: '0px' }}
                        >

                            <StudentDetail student_id={this.state.current_id} />
                        </Drawer>
                        :
                        null
                }
                {
                    this.state.isAddStudentM?<AddStudentM closeAddSM={this.closeAddSM} handleS={this.handleS} gradeLists={this.state.gradeLists} />:null
                }


               <StudentUpload onRef={e => { this.studentUpload = e }} />
            </div>
        )
    }
}


export default Form.create({})(withRouter(StudentLists))
