import React from 'react'
import './EditForm.scss'
import { Link } from 'react-router-dom'
import { Form, Col, Row, Table, Tag, Pagination } from 'antd'
import { stockDetail } from '@/api/stock'
import { STOCK_IN_TYPES, STOCK_OUT_TYPES } from '@/constants/stock'
const FormItem = Form.Item;

class EditForm extends React.Component{
    state = {
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.goods_id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'sku编码',
                dataIndex:'goods_sku_code',
                render:(text, rowData, index) => {
                    return (
                        <p>
                            {
                                rowData.sku_id
                                    ?
                                    rowData.sku_info.goods_sku_code
                                    :
                                    '--'
                            }
                        </p>
                    )
                }
            },
            {
                title:'单位',
                dataIndex:'unit',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.unit_name || '件'}</p>
                    )
                }
            },
            {
                title:'规格',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sku_info && rowData.sku_info.sku_names
                                    ?
                                    rowData.sku_info.sku_names.map((item,index) => (
                                        <div key={index}>
                                            <Tag className={`mgb5`}>{item}</Tag>
                                        </div>
                                    ))
                                    :
                                    '--'
                            }
                        </div>
                    )
                }
            },
            {
                title:'可用库存',
                dataIndex:'stock1',
                render:(text, rowData, index) => {
                    return(
                        <div>{ rowData.stock || 0 }</div>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            { this.state.itemInfo.type == 1 ? '入库数量' : '出库数量'}
                        </div>
                    )
                },
                dataIndex:'total',
                render:(text, rowData, index) => {
                    return(
                        <div>{ rowData.storage_num || 0 }</div>
                    )
                }
            },
        ],
        tableData:[],
        itemInfo:{},
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        if(this.props.record_id){
            sendData.record_id = this.props.record_id
            sendData.page = this.state.page;
            this.setState({
                tableLoading:true
            })
            stockDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.record_detail,
                            lists = detail.goods_list.data,
                            total = detail.goods_list.total,
                            per_page = detail.goods_list.per_page,
                            allStockTypes = [ ...STOCK_IN_TYPES,...STOCK_OUT_TYPES];
                        for(var i in allStockTypes){
                            if(allStockTypes[i]['id'] == detail.type){
                                detail['type_name'] = allStockTypes[i]['name']
                            }
                        }
                        lists.forEach(item => {
                            if(item.sku_info && item.sku_info.alias){
                                let sku_names = [];
                                for(var i in item.sku_info.alias){
                                    sku_names.push(item.sku_info.alias[i])
                                }
                                item.sku_info.sku_names = sku_names
                            }
                        })

                        this.setState({
                            itemInfo:detail,
                            tableData:lists,
                            total,per_page
                        })

                    }
                })
                .finally(() => {
                    this.setState({
                        tableLoading:false
                    })
                })

        }

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        let itemInfo = this.state.itemInfo || {}
        return(
            <div className={`drawer-cont`}>
                <div className={`msg-title`}></div>
                <Form className={'drawer-item pd20 '} layout={`inline`}>
                    <Row>
                        <Col span={8}>
                            <FormItem label={'单据号'} >
                                <div className={``}>
                                    <p>{itemInfo.service_code}</p>
                                </div>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={'入库类型'} >
                                <div className={``}>
                                    <p>{itemInfo.type_name}</p>
                                </div>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={'操作人'} >
                                <div className={``}>
                                    <p>{itemInfo.operater_name}</p>
                                </div>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <FormItem label={'门店/仓库'} >
                                <div className={``}>
                                    <p>{itemInfo.store ? itemInfo.store.name : '--'}</p>
                                </div>
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem label={'入库时间'} >
                                <div className={``}>
                                    <p>{itemInfo.operate_time}</p>
                                </div>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <div className={`msg-title`}></div>
                <div className={'drawer-item pd20 '}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Form.create({})( EditForm )

