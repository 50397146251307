import React from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Icon, Tooltip, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { addRemark, courseOrderExport, courseTrade } from '@/api/order'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import Remark from './components/remark'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

// 订单类型 1普通订单 2优惠券订单 3自助买单订单 4扫码下单订单 5KTV订单  8限时打折 9 积分兑换
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
class CourseLists extends React.Component {
    state = {
        activeStatus: '0',
        searchForm: {
            order_sn: '',
            user_name: '',
            user_mobile: '',
            pay_type: '',
            order_store_id: '',
            time_type: '',
            start_time: '',
            end_time: ''
        },
        tableData: [],
        storeLists: [],
        isLoading: false,
        noData: false,
        page: 1,
        total: 0,
        per_page: 10,
    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            isLoading: true
        })
        courseTrade(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData: lists,
                        total,
                        per_page
                    })
                    if (lists.length < 1) {
                        this.setState({
                            noData: true
                        })
                    } else {
                        this.setState({
                            noData: false
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
            .finally(() => { })
    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page: 1
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                sendData.status = this.state.activeStatus;
                this.setState({
                    searchForm,
                    tableLoading: true
                })
                courseOrderExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '订单导出.csv';
                        CX.exportExcel(blob, fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading: false
                        })
                    })
            }
        })
    }
    clickTab = val => {
        this.setState({
            activeStatus: val
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }
    
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleRemark = (rowData) => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = rowData.order_sn;
            if (!sendData.remark) { return false };
            this.setState({
                isLoading: true
            })
            addRemark(sendData)
                .then(res => {
                    if (res.code == 1) {
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })

        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }


    render() {

        let course_tag=item=>{
            if(item.charge_type==1){
                return item.course_num +"课时"
            }else if(item.charge_type==2){
                return item.course_time+["","天","月","季","年"][item.course_time_unit]
            }else{
                if(item.grade_info){
                    return item.grade_info.name +item.grade_info.start_date+"至"+item.grade_info.end_date
                }
                return ""
            }
        }

        const { getFieldDecorator } = this.props.form
        return (
            <div className={`shadow-radius`}>
                <Remark onRef={e => { this.Remark = e }}></Remark>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;商户单号</span>} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;客户昵称</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;客户手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;支付方式</span>} className={' fl'}>
                                {
                                    getFieldDecorator('pay_type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={2}>微信支付</Select.Option>
                                            <Select.Option value={4}>储值卡余额支付</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;订单类型</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('trade_type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={12} >课程订单</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;报名校区</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('order_store_id')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;时间筛选</span>} className={' fl'}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={1}>下单时间</Select.Option>
                                            <Select.Option value={2}>成交时间</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`} >
                            <FormItem className={'mgl80 fl'} >
                                <Button type='primary' onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={`0`}></TabPane>
                    <TabPane tab="待付款" key={`1`}></TabPane>
                    <TabPane tab="交易成功" key={`2`}></TabPane>
                </Tabs>
                <div className={''}>
                    <table className="cxtables cxtables-sku">
                        <colgroup>
                            <col width={`18%`} />
                            <col width={`8%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`8%`} />
                            <col width={`8%`} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>课程信息</td>
                                <td>价格/数量</td>
                                <td>客户</td>
                                <td>金额</td>
                                <td>学员信息</td>
                                <td>报名校区</td>
                                <td>订单状态</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        {
                            this.state.noData ?
                                <tbody>
                                    <tr>
                                        <td colSpan={8}>
                                            <div className={`lh25 txtCenter`}>暂无订单数据</div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                null
                        }

                    </table>
                    {
                        this.state.tableData.map((item, index) => (
                            <table className="cxtables cxtables-sku mgt10" key={index}>
                                <colgroup>
                                    <col width={`18%`} />
                                    <col width={`8%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`8%`} />
                                    <col width={`8%`} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td colSpan={8} className={`ftnormal clearfix`}>
                                            <Tag color={`#ff0000`}>{item.trade_type_name}</Tag>
                                            <span>商户单号：<span>{item.order_sn}</span></span>
                                            <Icon type="file-text" className={`mgl5 cursor`} onClick={() => { this.handleCopy(item.order_sn) }} />
                                            <span className={`mgl10`}>下单时间：<span>{item.created_at}</span></span>
                                            {
                                                item.remark
                                                    ?
                                                    <Tooltip placement="left" title={`${item.remark}`}>
                                                        <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/6b8f2e533456302124ee110cd006b18f.png" width={20} className={`fr cursor`} alt="" />
                                                    </Tooltip>
                                                    :
                                                    <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/610b1cfaa3f361987ea789c8cacd7a8a.png" width={20} className={`fr cursor`} alt="" onClick={() => { this.handleRemark(item) }} />
                                            }
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={`noPadding bd-right`} colSpan={2}>
                                            <div className={`clearfix`}>

                                                {
                                                    item.goods_lists && item.goods_lists.map((good, goodIndex) => (
                                                        <div className={`clearfix bd-bottom`} key={goodIndex}>
                                                            <div className={`flex jusify-start align-center td-item fl`} style={{ width: '75%' }}>
                                                                <img src={good.pic_urls[0]} alt="" width={60} />
                                                                <div className={`mgl10`}>

                                                                    <div className={`lh25`}>{good.name ? good.name : ''}</div>
                                                                    <div className={`lh25 `}>{course_tag(good)}</div>
                                                                </div>
                                                            </div>
                                                            <div className={` td-price fr`}>
                                                                <div className={`lh25 txtRight`}>&yen;{good.price}</div>
                                                                <div className={`lh25 txtRight`}>x{good.num}</div>
                                                                {
                                                                    good.refund_info
                                                                        ?
                                                                        <div className={`lh25 txtRight colorRed`}>已退{good.refund_info.refund_num}件</div>
                                                                        :
                                                                        null
                                                                }
                                                               

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.name || "-"}</div>
                                            <div className={`lh25`}>{item.phone || "-"}</div>
                                        </td>
                                        <td>
                                            {
                                                [0, 6, 7, 8].includes(item.trade_status)
                                                    ?
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>待支付：￥{item.pay_ment}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>支付合计：￥{item.pay_ment}</div>

                                                    </div>
                                            }
                                            {
                                                item.trade_extends && item.refund_price > 0
                                                    ?
                                                    <div className={`lh25 colorRed`}>退款金额：￥{item.refund_price || '-'}</div>
                                                    :
                                                    null
                                            }
                                            <div className={`lh25`}>支付方式：{item.pay_type_name || '-'}</div>
                                            <div className={`lh25`}>支付时间：{item.pay_time || '-'}</div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.student_info ? item.student_info.real_name : '-'}</div>
                                            <div className={`lh25`}>{item.student_info ? item.student_info.parent_mobile : '-'}</div>
                                            {
                                                item.user_remark
                                                    ?
                                                    <div className={`lh25`}>
                                                        <Tooltip placement="right" title={`${item.user_remark}`}>
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/0ad4ac21baead02dd06edf8431a7a1ed.png" alt="" width={20} className={`cursor`} />
                                                        </Tooltip>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.store ? item.store.name : '-'}</div>
                                        </td>
                                        <td>
                                            <div>
                                                {
                                                    [1, 2].includes(item.trade_status)
                                                        ?
                                                        <Tag color="green">{item.trade_status_name}</Tag>
                                                        :
                                                        null
                                                }
                                                {
                                                    [4, 6, 7, 8].includes(item.trade_status)
                                                        ?
                                                        <Tag>{item.trade_status_name}</Tag>
                                                        :
                                                        null

                                                }
                                                {
                                                    [0].includes(item.trade_status)
                                                        ?
                                                        <Tag color="orange">{item.trade_status_name}</Tag>
                                                        :
                                                        null

                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={'btn-row'}>
                                                <Link to={`/order/detail/${item.order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        ))
                    }

                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}


            </div>
        )
    }


}

export default Form.create({})(withRouter(CourseLists))
