import React from 'react'
import { Modal, Input, Button, Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { marketItemLists } from '@/api/item'
import CX from '@/utils/CX'

export default class MarketItemPicker extends React.Component{

    state = {
        title:'选择商品',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p className={`lh25`}>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <span className={`lh25`}>{ rowData.price ? `${rowData.price}元` : `--` }</span>
                        </div>
                    )
                }
            },
            {
                title:'销量',
                dataIndex:'real_sales',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.real_sales || '--'}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return <Tag color='green'>上架中</Tag>
                }
            },
        ],
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        per_page:5,
        total:0,
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.selectIds){
            this.setState({
                selectedRowKeys:nextProps.selectIds
            })
        }
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback: callback ? callback : null
        })
        this.fetchData();
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    fetchData = () => {
        let sendData = {
            page:this.state.page,
            name:this.state.name,
            per_page:this.state.per_page
        }
        this.setState({
            tableLoading:true
        })
        marketItemLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.goods_list.data,
                        total = res.data.goods_list.total,
                        per_page = res.data.goods_list.per_page;
                    this.renderSelectRows(tableData)
                    this.setState({
                        tableData, per_page, total
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if(selectedRowKeys.length){
            for(var k in lists){
                if(selectedRowKeys.includes(lists[k]['id'])){
                    let selectRowsIds = selectRows.map(item => {return item.id})
                    if(!selectRowsIds.includes(lists[k]['id'])){
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }


    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(this.state.selectRows)
        }
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val =>  {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name: e.target.value
        })
    }

    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    })


                }
            }
        }
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}

                >
                    <div className={'title clearfix'}>
                        <div className={'fr btn-group'}>
                            <Input value={this.state.name} onChange={this.getInputValue} placeholder={'输入商品名称'} style={{'width':'180px'}} />
                            <Button type={'primary'} icon={'search'} style={{'verticalAlign':'top'}} onClick={this.fetchData}>搜索</Button>
                        </div>
                    </div>
                    <div className={'mgt20'}>
                        <Table
                            size={`middle`}
                            rowSelection={rowSelection}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        >
                        </Table>
                        {
                            this.state.tableData.length
                                ?
                                <div className={'clearfix mgt10'}>
                                    <div className={'fl'}>
                                        <p>已选择{this.state.selectedRowKeys.length}样商品</p>
                                    </div>
                                    <div className={'fr'}>
                                        <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    </div>
                                </div>
                                :
                                ''
                        }

                    </div>

                </Modal>
            </div>
        )

    }

}


