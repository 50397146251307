import React from 'react'
import { Form, Input, Button, Tag, Table, Pagination, Divider, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { timesCardLists, suitStores, timesCardDelete, timesCardStatus } from '@/api/times-card'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CouponFitStore from '@/views/coupon/components/CouponFitStore'
const FormItem = Form.Item;

class TimesCard extends React.Component{
    state = {
        columns:[
            {
                title:'卡名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || ''}</p>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{ rowData.effect_time }</p>
                        </div>
                    )
                }
            },
            {
                title:'适用门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                rowData.suit_store_type == 1
                                    ?
                                    '全部'
                                    :
                                    <span className={`fi-link`} onClick={ () => { this.handleSuitStore(rowData) } }>{ rowData.suit_store_name }</span>
                            }
                        </div>
                    )
                }
            },
            {
                title:'领取数量',
                dataIndex:'giveout_num',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.giveout_num}</p>
                    )
                }
            },
            {
                title:'排序',
                dataIndex:'sort',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.sort}</p>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? `green` : rowData.status == 2 ? `orange`: '' }>{ rowData.status_name }</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDetail(rowData)}}>详情</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 1
                                    ?
                                    <Button type={"link"} size={"small"}  onClick={() => {this.handleOff(rowData)}}>下架</Button>
                                    :
                                    <Button type={"link"} size={"small"}  onClick={() => {this.handleOn(rowData)}}>上架</Button>
                            }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"}  onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            id:'',
            name:''
        },
        tableData:[],
        suitStores:[],
        tableLoading:false,
        selectRows:[],
        page:1,
        total:0,
        per_page:10

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        timesCardLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/user/add-times-card`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/user/add-times-card/${rowData.id}`
        })
    }
    handleDetail = rowData => {
        this.props.history.push({
            pathname:`/user/times-card-detail/${rowData.id}`
        })
    }
    patchDelete = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要删除的卡')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        Modal.confirm({
            title:'确定要删除这些卡吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    patchOn = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要上架的卡')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 1
        Modal.confirm({
            title:'确定要上架这些卡吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    patchOff = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要下架的卡')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 2
        Modal.confirm({
            title:'确定要下架这些卡吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleOff = row => {
        let sendData = {}
        sendData.ids = [row.id];
        sendData.status = 2;
        Modal.confirm({
            title:`是否将这张卡下架`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleOn = row => {
        let sendData = {}
        sendData.ids = [row.id];
        sendData.status = 1;
        Modal.confirm({
            title:`是否将这张卡上架`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleDelete = row => {
        let sendData = {}
        sendData.ids = [row.id];
        Modal.confirm({
            title:`是否删除这张卡`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                timesCardDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleStatus = (rowData) => {
        message.info('敬请期待')
    }
    handleShare = (rowData) => {
        message.info('敬请期待')
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                { this.state.isLoading ? <Loading /> : null }

                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'卡片称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='请输入商品名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增次卡</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.patchDelete}>批量删除</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOn}>批量上架</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOff}>批量下架</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Form.create({})(withRouter(TimesCard))
