export const TRADE_TYPES = [
    { trade_type:0, name:'全部' },
    { trade_type:1, name:'商品' },
    { trade_type:2, name:'优惠券' },
    { trade_type:3, name:'自助买单' },
    { trade_type:4, name:'扫码点餐' },
    { trade_type:6, name:'储值卡开卡' },
    { trade_type:7, name:'储值卡充值' },
    { trade_type:11, name:'次卡开卡' },
    { trade_type:8, name:'限时折扣' },
    { trade_type:9, name:'积分兑换' },
    { trade_type:14, name:'拼团' },
    { trade_type:15, name:'社群团购' },
]
