import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Select, Button, Table, Pagination, Divider, Tag, Modal } from 'antd'
import { couponLists, couponStop, couponRestart, couponDelete, suitStores } from '@/api/coupon'
import Loading from '@/components/Loading'
import message from '@/utils/message';
import CouponFitStore from './components/CouponFitStore'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item

class CouponLists extends React.Component{
    state = {
        searchForm:{
            name:'',
            coupon_type:'',
            status:''
        },
        columns:[
            {
                title:'优惠券名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'券类型',
                dataIndex:'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title:'券描述',
                dataIndex:'description',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.coupon_extend.desc || '-'}</p>
                    )
                }
            },
            {
                title:'领取方式',
                dataIndex:'draw_way',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.draw_way }} className={`lh25`}></div>
                    )
                }
            },
            {
                title:'适用门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                rowData.suit_store_type == 1
                                    ?
                                    '全部'
                                    :
                                    <span className={`fi-link`} onClick={ () => { this.handleSuitStore(rowData) } }>{ rowData.suit_store_name }</span>
                            }
                        </div>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title:'剩余数量',
                dataIndex:'last_num',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.last_num}</p>
                    )
                }
            },
            {
                title:'领用情况',
                dataIndex:'giveout_num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>已领：{ rowData.giveout_num }</p>
                            <p className={`lh25`}>已用：{ rowData.use_num || 0 }</p>
                        </div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 4 ? `green` : rowData.status == 1 ? `orange`: '' }>{ rowData.status_name }</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 1
                                    ?
                                    <div className={'btn-row'}>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>推广</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleStop(rowData)}}>停止发放</Button>
                                    </div>
                                    :
                                    <div className={'btn-row'}>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleView(rowData)}}>查看</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleStart(rowData)}}>继续发放</Button>
                                    </div>
                            }
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        suitStores:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:''

    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        couponLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.coupon_list.data,
                        total = res.data.coupon_list.total,
                        per_page = res.data.coupon_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/coupon/add`
        })
    }
    handleShare = (rowData) => {
        this.setState({
            poster:rowData.code_url,
            path:`/pages/coupon/detail?id=${rowData.id}`
        },() => {
            this.ShareModal.show()
        })
    }
    handleEdit = (rowData) => {
        this.props.history.push({
            pathname:`/coupon/add/${rowData.id}`
        })
    }
    handleView = (rowData) => {
        this.props.history.push({
            pathname:`/coupon/add/${rowData.id}/2`
        })
    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }


    handleDelete = (rowData) => {
        let sendData = {};
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:'提示',
            content:'删除优惠券后不可恢复，是否确认删除？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                couponDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleStart = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id;
        Modal.confirm({
            title:'提示',
            content:'继续发放后，用户可继续领取优惠券，确认进行此操作吗？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                couponRestart(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleStop = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id;
        Modal.confirm({
            title:'提示',
            content:'停止发放后，优惠券将不能继续领取、不能编辑，但并不影响领取成功后的优惠券使用。确认要进行此操作吗？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                couponStop(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div >
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>优惠券名称</span>} className={'fl'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入优惠券名称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'优惠券类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('coupon_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>满减券</Select.Option>
                                            <Select.Option value={2}>折扣券</Select.Option>
                                            <Select.Option value={3}>兑换券</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'优惠券状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>发放中</Select.Option>
                                            <Select.Option value={2}>已领完</Select.Option>
                                            <Select.Option value={3}>已过期</Select.Option>
                                            <Select.Option value={4}>停止发放</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} colon={false} className={' fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                        
                    </Form>
                </div>
                <div className={''}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增优惠券</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                { this.state.isLoading ? <Loading /> : null }

            </div>
        )
    }
}

export default Form.create({})(withRouter(CouponLists))
