import React from 'react'
import { Form, Input, Button, Table, Pagination, Divider, Select, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { comboLists, suitStores, saveCombo, comboDetail, comboDelete, copyCombo } from '@/api/combo'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import { storeRoom } from '@/api/desk'
import AddMeal from './add-meal'
import Cookie from 'js-cookie'
const FormItem = Form.Item

class KtvMeal extends React.Component{
    state = {
        searchForm:{
            name:'',
            store_id:''
        },
        columns:[
            {
                title:'套餐名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'套餐图片',
                dataIndex:'room_num',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <img src={ rowData.cover } width={60} alt=""/>
                        </div>
                    )
                }
            },
            {
                title:'套餐内容',
                dataIndex:'content',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.content}</p>
                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'create_at',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.created_at}</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleEdit(rowData) } }>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleCopy(rowData) } }>复制</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleDelete(rowData) } }>删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:null
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()

    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        comboLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.combo_list.data,
                        total = res.data.combo_list.total,
                        per_page = res.data.combo_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleAdd = () => {
        let sendData = {}
        this.setState({
            currentRow:null
        },() => {
            this.AddMeal.show(res => {
                if(res){
                    sendData = res;
                    this.setState({
                        isLoading:true
                    })
                    saveCombo(sendData)
                        .then(result => {
                            if(result.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }

            })
        })

    }
    handleEdit = (row) => {
        let sendData = {}
        this.setState({
            currentRow:row
        },() => {
            this.AddMeal.show(res => {
                if(res){
                    sendData = res;
                    sendData.id = row.id || ''
                    this.setState({
                        isLoading:true
                    })
                    saveCombo(sendData)
                        .then(result => {
                            if(result.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }

            })
        })
    }
    handleCopy = (row) => {
        let sendData = {}
        sendData.id= row.id || '';
        Modal.confirm({
            title:'是否复制该套餐？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                copyCombo(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('复制成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleDelete = (row) => {
        let sendData = {}
        sendData.ids = [row.id];
        Modal.confirm({
            title:'是否删除该套餐？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                comboDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })

    }

    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        let storeLists = this.props.storeLists || []
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <AddMeal onRef={ e => { this.AddMeal = e } } storeLists={ storeLists } data={ this.state.currentRow } ></AddMeal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'套餐名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索套餐名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'选择门店'} className={'fl mgl10'}>
                            {
                                getFieldDecorator('store_id',{
                                    initialValue:0
                                })(
                                    <Select style={{width:170}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        {
                                            storeLists.map(store => (
                                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增套餐</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(KtvMeal))
