import React, { Component } from 'react'
import { Table, Pagination, Button, Divider, Form ,Select,Tag,message ,Modal} from 'antd'
import { studentPhysical ,studentPhysicalDele} from '@/api/student'
import AddBMI from './addBMI'
const FormItem = Form.Item;
const { Option } = Select;

class BMITab extends Component {
    state = {
        columns: [
            {
                title:<span className='mgl16'>体测表名称</span> ,
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p className='mgl16'>{rowData?.physical_form?.name ||''}</p>
                }
            },
            {
                title: '体测项目数量',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData?.physical_form?.item_num ||''}</p>
                }
            },
            {
                title: '体测时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    let item=rowData.static_data.find(val=>val.id=='1');
                    return <p>{item?.value||"-"}</p>
                }
            },
            {
                title: '任课教练',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    let item=rowData.static_data.find(val=>val.id=='2');
                    return <p>{item?.value||"-"}</p>
                }
            },
            {
                title: '操作',
                dataIndex: '8',
                render: (text, rowData, index) => {

                    return (
                        <div>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleDele(rowData)}}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        status:0
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.student_id = this.props.student_id
        sendData.page = this.state.page
        sendData.per_page = this.state.per_page
      
        this.setState({
            tableLoading: true
        })
        studentPhysical(sendData).then(res => {

            if (res.code == 1) {
                let list = res.data.list
                let total = list.total,
                    per_page = list.per_page,
                    tableData = list.data
                this.setState({
                    total, per_page, tableData
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    handleAdd=()=>{
        this.AddBMI.show(()=>{
            this.fetchData()
        })
    }

    handleEdit = row => {
        this.AddBMI.show(()=>{
            this.fetchData()
        },row)
    }
    handleDele = row => {
        Modal.confirm({
            title: `是否确认删除？`,
            onOk: () => {
                studentPhysicalDele({student_id:this.props.student_id,user_physical_form_id:row.id}).then(res=>{
                    if(res.code==1){
                        message.success('删除成功！')
                        this.fetchData()
                    }
                })
            }
        });
    }
 




    render() {
        return (
            <div className='pdb20'>
                <div>
                    <Button type={'primary mgl24 mgb20'} onClick={this.handleAdd} icon={'plus'}>添加体测表</Button>
                </div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <AddBMI onRef={e=>{this.AddBMI=e}} student_id={this.props.student_id} ></AddBMI>
            </div>
        )
    }
}

export default BMITab
