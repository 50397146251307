import React from 'react'
import { Form, Select, DatePicker, Input, Button, Tooltip, Pagination, Tag, Icon } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import '@/assets/css/finance/index.scss'
import { fxCommissionRecord, exportCommissionRecord } from '@/api/fx'
import { storeLists } from '@/api/store'
import moment from 'moment'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class FxCommissionDetail extends React.Component{
    state = {
        searchForm:{
            order_sn:'',
            name:'',
            mobile:'',
            type:'',//变动类型 1-分销佣金 2-邀请奖励佣金 3-团长佣金 4-商家调整 5-提现扣除
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'商户单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.order_sn || '--'}</div>
                    )
                }
            },
            {
                title:'订单类型',
                dataIndex:'trade_type_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.trade_type_name || '--'}</div>
                    )
                }
            },
            {
                title:'商品名称',
                dataIndex:'goods_info',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.goods_info || '--'}</div>
                    )
                }
            },
            {
                title:'客户',
                dataIndex:'user',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={`lh25`}>{ rowData.user ? rowData.user.name : '--'}</div>
                            <div className={`lh25`}>{ rowData.user ? rowData.user.mobile : '--'}</div>
                        </div>
                    )
                }
            },
            {
                title:'支付信息',
                dataIndex:'pay_info',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>实收金额：&yen;{rowData.pay_ment}</p>
                            <p className={`lh25`}>支付方式：{rowData.pay_type_name}</p>
                            {
                                rowData.trade_status == 4
                                    ?
                                    <p className={`lh25`}>退款时间：{ rowData.refund_time }</p>
                                    :
                                    <p className={`lh25`}>完成时间：{ rowData.finished_at }</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'下单门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.store ? rowData.store.name : ''}</div>
                    )
                }
            },
            {
                title:'订单状态',
                dataIndex:'trade_status_name',
                render:(text, rowData, index) => {
                    return (
                        <Tag >{rowData.trade_status_name || '--'}</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <Button type={`link`} onClick={ () => { this.handleDetail(rowData) } }>查看详情</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        storeLists:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        balance_commission:0,
        to_handle_commission:0
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        fxCommissionRecord(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page,
                        balance_commission = res.data.balance_commission,
                        to_handle_commission = res.data.to_handle_commission,
                        tableData = [];
                    lists.forEach((item,index) => { item.rowSpan = 1 })
                    let tmp = CX.groupBy(lists,'order_sn');
                    for(var i in tmp){
                        if(i){
                            let tmpArr = tmp[i];
                            for(var j in tmpArr){
                                if(tmpArr.length == 1){
                                    tmpArr[j]['rowSpan'] = 1;
                                }
                                if(tmpArr.length == 2){
                                    if(j == 0){
                                        tmpArr[j]['rowSpan'] = 2;
                                    }else {
                                        tmpArr[j]['rowSpan'] = 0;
                                    }
                                }
                                tableData.push(tmpArr[j])
                            }

                        }else {
                            tableData.push(...tmp[i])
                        }
                    }
                    this.setState({
                        tableData:tableData,
                        total,
                        per_page,
                        balance_commission,
                        to_handle_commission
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.time_type == 1){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else if(values.time_type == 2){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else {
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                }
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                this.setState({
                    searchForm,
                    isLoading:true
                })
                exportCommissionRecord(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '佣金收益明细.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })



            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={`content-head mgb15`}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={`商户单号`} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`客户昵称`} className={'fl mgl20'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户昵称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`客户电话`} className={'fl mgl20'} >
                                {
                                    getFieldDecorator('mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>

                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={`收益类型`} className={`fl`}>
                                {
                                    getFieldDecorator('type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>分销佣金</Select.Option>
                                            <Select.Option value={2}>邀请奖励</Select.Option>
                                            <Select.Option value={3}>团长佣金</Select.Option>
                                            <Select.Option value={4}>商家调整</Select.Option>
                                            <Select.Option value={5}>提现扣除</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={`发放时间`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;</span>} colon={false} className={'fl mgl5'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>


                        </div>
                    </Form>
                </div>
                <div className={`flex justify-start align-center`}>
                    <div className={`finance-data-cont`}>
                        <div className="data-finance-item no-border">
                            <div className="txt1">
                                <div className="item-txt">
                                    已结算佣金（元）
                                    <Tooltip placement="topLeft" title={`订单完结后发放的佣金金额的总和`}>
                                        <Icon type="question-circle"  className={`mgl5`} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="chart-num">{ this.state.balance_commission }</div>
                        </div>
                        <div className="data-finance-item no-border">
                            <div className="txt1">
                                <div className="item-txt">
                                    待结算佣金（元）
                                    <Tooltip placement="topLeft" title={`包含订单待使用，待确认收货状态下的佣金，需要发放佣金金额的总和`}>
                                        <Icon type="question-circle"  className={`mgl5`} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="chart-num">{ this.state.to_handle_commission }</div>
                        </div>
                    </div>

                </div>
                <table className="cxtables cxtables-sku mgt15" >
                    <colgroup>
                        <col width={`10%`} />
                        <col width={`8%`} />
                        <col width={`10%`} />
                        <col width={`10%`} />
                        <col width={`8%`} />
                        <col width={`12%`} />
                        <col width={`10%`} />
                        <col width={`8%`} />
                        <col width={`15%`} />
                        <col width={`10%`} />
                    </colgroup>
                    <thead>
                        <tr>
                            <td>商户单号</td>
                            <td>实付金额</td>
                            <td>客户信息</td>
                            <td>分销员</td>
                            <td>收益类型</td>
                            <td>收益金额</td>
                            <td>下单门店</td>
                            <td>结算状态</td>
                            <td>发放时间</td>
                            <td>操作</td>
                        </tr>
                    </thead>
                    {
                        this.state.tableData.length
                            ?
                            <tbody>
                            {
                                this.state.tableData.map((item,index) => (
                                    <tr key={index}>
                                        {
                                            item.rowSpan > 0
                                                ?
                                                <td rowSpan={item.rowSpan}>{ item.order_sn || '-' }</td>
                                                :
                                                null
                                        }
                                        {
                                            item.rowSpan > 0
                                                ?
                                                <td rowSpan={item.rowSpan}>{ item.pay_ment ? `${item.pay_ment}元` : '-' }</td>
                                                :
                                                null
                                        }
                                        {
                                            item.rowSpan > 0
                                                ?

                                                <td rowSpan={item.rowSpan}>
                                                    {
                                                        item.trade_user
                                                            ?
                                                            <div>
                                                                <p>{ item.trade_user.name }</p>
                                                                <p>{ item.trade_user.mobile }</p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p>-</p>
                                                            </div>
                                                    }

                                                </td>
                                                :
                                                null
                                        }
                                        <td>
                                            <p>{ item.name }</p>
                                            <p>{ item.mobile }</p>
                                        </td>
                                        <td>{ item.type_name }</td>
                                        <td>
                                            <p>{ item.change_commission }元</p>
                                            <p>手续费：-{item.commission_charge}元</p>
                                        </td>
                                        <td>{ item.store_name || '-' }</td>
                                        <td>
                                            <Tag color={`green`}>已结算</Tag>
                                        </td>
                                        <td>{ item.created_at }</td>
                                        <td>
                                            {
                                                item.order_sn
                                                    ?
                                                    <Link to={`/order/detail/${item.order_sn}`} target={`_blank`} className={`fi-link`}>查看详情</Link>
                                                    :
                                                    '-'
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className={`lh25 txtCenter`}>暂无数据</div>
                                    </td>
                                </tr>
                            </tbody>
                    }

                </table>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }


            </div>
        )
    }
}

export default Form.create({})(withRouter(FxCommissionDetail))
