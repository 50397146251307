import React, { Component } from 'react'
import { Form, Input, Radio, Button, Select, Icon, InputNumber, Switch, Tooltip, DatePicker, message, Row, Col } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Loading from '@/components/Loading'
import Editor from '@/components/Editor/Editor'
import Mapper from '@/components/Mapper/Mapper'
import RuleNotice from './components/rule-notice'
import moment from 'moment'
import CX from '@/utils/CX'

import { labelGroupList} from '@/api/user'
import { ticketDetail, ticketSave } from '@/api/ticket'

const { RangePicker } = DatePicker;
const FormItem = Form.Item

class TicketAdd extends Component {
    state = {
        isLoading: false,
        formData: {
            ahead_minutes: 30,
            ahead_limit_buy_num: 1,
            limit_buy_num: 1
        },
        tagsLists:[],
        showEditor: false,
        is_teacher: false,
        ticket_data: [
            {
                price_name: 'VIP票',
                price: '',
                num: '',
                id: 1
            },
            {
                price_name: '普通票',
                price: '',
                num: '',
                id: 2
            },
        ]

    }

    componentDidMount() {

        if (this.props.match.params.id) {
            this.fetchData()
        } else {
            this.setState({
                showEditor: true
            })
        }
        this.fetchTages()
    }
    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        ticketDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail
                    let ticket_data = this.state.ticket_data
                    detail.perform_start_time = detail.perform_start_time ? moment(detail.perform_start_time) : null
                    detail.perform_end_time = detail.perform_end_time ? moment(detail.perform_end_time) : null
                    if (detail.ticket_start_time && detail.ticket_end_time) {
                        detail.time = [moment(detail.ticket_start_time), moment(detail.ticket_end_time)]
                    }

                    if (!detail.limit_buy_num) {
                        detail.quota_rule = 1
                        detail.limit_buy_num = 1
                    } else {
                        detail.quota_rule = 2
                    }

                    this.setState({
                        formData: detail,
                        ticket_data: detail.ticket_data || ticket_data,
                        showEditor: true
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    fetchTages = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        labelGroupList(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,arr=[];

                    lists.forEach(item=>{
                        let ids=item.label_value.split(','),
                            names=item.label_value_name.split(',');
                        ids.forEach((val,i)=>{
                            arr.push({
                                id:item.id+'_'+ids[i],
                                name:item.group_name+'-'+names[i]
                            })
                        })
                    })


                    this.setState({
                        tagsLists: arr
                    })
                }
            })
            .finally(() => {
            })
    }


    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleSort = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = urls.splice(oldIndex, 1)[0];
        urls.splice(newIndex, 0, targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'promote_img':
                case 'cover':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if (formData['pic_urls'].length > 10) {
                        formData['pic_urls'] = formData['pic_urls'].slice(0, 10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }

    changPrice = (name, index) => {
        return e => {
            let value = e.target.value,
                ticket_data = this.state.ticket_data;
            ticket_data[index][name] = value
            this.setState({
                ticket_data: [...ticket_data]
            })

        }
    }

    priceAdd = () => {
        let ticket_data = this.state.ticket_data;
        ticket_data.push({
            price_name: '',
            price: '',
            num: '',
            id: CX.genUUID()
        })
        this.setState({
            ticket_data: [...ticket_data]
        })
    }
    deletePrice = (index) => {
        return e => {
            let ticket_data = this.state.ticket_data;
            ticket_data.splice(index, 1)
            this.setState({
                ticket_data: [...ticket_data]
            })

        }
    }

    handleSelet = (name) => {
        return e => {
            let formData = this.state.formData
            formData[name] = e
            this.setState({
                formData: { ...formData }
            })
        }
    }
    handleInput = (name) => {
        return e => {
            let formData = this.state.formData
            formData[name] = e.target.value
            this.setState({
                formData: { ...formData }
            })
        }
    }

    showMap = e => {
        this.Mapper.show(res => {
            let formData = this.state.formData || {};
            formData['perform_place'] = res.address
            formData['place_lat'] = res.lat
            formData['place_lng'] = res.lng
            formData['detail_place'] = res.map_address
            console.log(res);
            this.props.form.setFieldsValue({
                perform_place: res.address || '',
            });
            this.setState({
                formData
            })
        })
    }



    saveData = () => {
        let formData = this.state.formData || {}, ticket_data = this.state.ticket_data,
            sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                sendData = { ...formData, ...values }

                sendData.ticket_start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                sendData.ticket_end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                sendData.perform_start_time = values.perform_start_time ? moment(values.perform_start_time).format('YYYY-MM-DD HH:mm') : ''
                sendData.perform_end_time = values.perform_end_time ? moment(values.perform_end_time).format('YYYY-MM-DD HH:mm') : ''

                sendData.ticket_desc = this.ticketRef.state.tabList
                sendData.perform_desc = this.performRef.state.tabList
                sendData.ticket_data = ticket_data

                if (sendData.quota_rule == 1) {
                    sendData.limit_buy_num = 0
                }

                if(values.label_ids){
                    sendData.label_ids=values.label_ids.join(',');
                }

                console.log(sendData);
                this.setState({
                    isLoading: true
                })
                if (this.props.match.params.id) {
                    sendData.id = this.props.match.params.id
                }
                ticketSave(sendData).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                            .then(() => {
                                this.props.history.push({
                                    pathname: `/ticket/lists`
                                })
                            })

                    }
                })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    goBack = () => {
        this.props.history.goBack()
    }




    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        const radioSty = { display: "block", marginBottom: "8px" }
        return (
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>

                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <Mapper onRef={e => { this.Mapper = e }} lat={data.place_lat || ''} lng={data.place_lng || ''} address={data.address || ''}></Mapper>
                    <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                        <div className={`msg-title mgb15`}>基本信息</div>
                        <FormItem label={'演出名称'}>
                            {getFieldDecorator('name', {
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入演出名称'
                                    }
                                ]
                            })(<Input
                                style={{ width: 300 }}
                                placeholder={'请输入演出名称'}
                                maxLength={30}
                                suffix={<span>{getFieldValue("name").length}/30</span>}
                            />)}
                            <span className={`fi-help-text`}>演出名称最多 30 个字符</span>
                        </FormItem>

                        <FormItem label={'开票时间'}>
                            {getFieldDecorator('time', {
                                initialValue: data.time || null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择开票时间'
                                    }
                                ]
                            })(
                                <RangePicker
                                    style={{ width: 400 }}
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder={['开始时间', '结束时间']}
                                />
                            )}
                        </FormItem>

                        <FormItem label={'演出封面'} required>
                            <div className={`clearfix`}>
                                {
                                    data.cover
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.cover}
                                            onDel={e => { this.delImg(e, 'cover') }}
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'cover') }}
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}>建议图片尺寸3:4，大小不超过4M</span>
                        </FormItem>

                        <FormItem label={'演出开始时间'}>
                            {getFieldDecorator('perform_start_time', {
                                initialValue: data.perform_start_time || null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择演出开始时间'
                                    }
                                ]
                            })(
                                <DatePicker
                                    style={{ width: 300 }}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    placeholder="开始时间" />
                            )}
                        </FormItem>

                        <FormItem label={'演出结束时间'}>
                            {getFieldDecorator('perform_end_time', {
                                initialValue: data.perform_end_time || null,
                            })(
                                <DatePicker
                                    style={{ width: 300 }}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    placeholder="结束时间" />
                            )}
                        </FormItem>

                        <FormItem label={'演出场地'}>
                            {getFieldDecorator('perform_place', {
                                initialValue: data.perform_place || '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择演出场地'
                                    }
                                ]
                            })(<Input placeholder={'点击添加地址'} maxLength={30} onClick={this.showMap} />)}
                        </FormItem>

                        <FormItem label={'支持选座'}>
                            {getFieldDecorator('select_seat', {
                                initialValue: !!data.select_seat,
                                valuePropName: 'checked',
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择'
                                    }
                                ]
                            })(
                                <Switch />
                            )}
                            <span className={'fi-help-text'}>开启后，小程序端购票支持选座位</span>
                        </FormItem>

                        <FormItem label={"票档价格"} required>
                            {
                                this.state.ticket_data.map((val, i) => {
                                    return (
                                        <Row gutter={[8, 4]} key={i}>
                                            <Col span={3}>
                                                <Input value={val.price_name} onChange={this.changPrice('price_name', i)} />
                                            </Col>
                                            <Col span={5}>
                                                <Input addonAfter="元" placeholder='请输入票价金额' value={val.price} onChange={this.changPrice('price', i)} />
                                            </Col>
                                            <Col span={5}>
                                                <Input addonAfter="张" placeholder='请输入对应总数量' value={val.num} onChange={this.changPrice('num', i)} />
                                            </Col>
                                            <Col span={3}>
                                                {
                                                    i > 1 ? <Button type='link' size='smell' onClick={this.deletePrice(i)}>删除</Button> : null
                                                }

                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Button type='link' onClick={this.priceAdd} >新增票档</Button>
                                </Col>

                            </Row>
                        </FormItem>




                        <FormItem label={"取票方式"} >
                            {getFieldDecorator('take_ticket_type', {
                                initialValue: data.take_ticket_type || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择取票方式'
                                    }
                                ]
                            })(
                                <Radio.Group >
                                    <Radio value={1}>自提</Radio>
                                    <Radio value={2}>顺丰到付</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        <div className={`msg-title mgb15`}>规则设置</div>

                        <FormItem label={'实名观影'}>
                            {getFieldDecorator('real_join', {
                                initialValue: !!(data.real_join ?? 1),
                                valuePropName: 'checked',
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择'
                                    }
                                ]
                            })(
                                <Switch />
                            )}
                            <span className={'fi-help-text'}>开启后，小程序端购票时需填写身份证号码，实名购票，一个身份证号对应一张票</span>
                        </FormItem>

                        <FormItem label={'优先购'}>
                            {getFieldDecorator('ahead_buy', {
                                initialValue: !!data.ahead_buy,
                                valuePropName: 'checked',
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择'
                                    }
                                ]
                            })(
                                <Switch />
                            )}
                            <div>指定的标签用户可在开票前
                                <Select style={{ width: 100 }} placeholder={'请选择'} className='mgl10 mgr10' onChange={this.handleSelet('ahead_minutes')} value={data.ahead_minutes}  >
                                    {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60,90,120,150,180,210,240,270,300,330,360].map(val => {
                                        return <Select.Option key={val} value={val}>{val}</Select.Option>
                                    })}
                                </Select>
                                分钟提前进入页面购买，每人限购  <Input className='mgl10 mgr10' style={{ width: 80 }} onChange={this.handleInput('ahead_limit_buy_num')} value={data.ahead_limit_buy_num} /> 张</div>

                        </FormItem>
                        {
                            getFieldValue('ahead_buy')
                                ?
                                <FormItem label={'标签用户'}>
                                    {getFieldDecorator('label_ids', {
                                        initialValue: data.label_ids_ary || [],
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择标签'
                                            }
                                        ]
                                    })(
                                        <Select mode="multiple" style={{ width: '300px' }} placeholder="请选择">
                                            {
                                                this.state.tagsLists.map(store => (
                                                    <Select.Option
                                                        value={store.id}
                                                        key={store.id}
                                                    >{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                :
                                ''
                        }

                        <FormItem label={"限购规则"} >
                            {getFieldDecorator('quota_rule', {
                                initialValue: data.quota_rule || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择限购规则'
                                    }
                                ]
                            })(
                                <Radio.Group >
                                    <Radio value={1}>不限购</Radio>
                                    <Radio value={2}>每人限购<Input className='mgl10 mgr10' style={{ width: 80 }} onChange={this.handleInput('limit_buy_num')} value={data.limit_buy_num} />张</Radio>
                                </Radio.Group>
                            )}
                             <span className={'fi-help-text'}>当开启实名观演时，每个ID限购2张，则购票时需选择2个身份证信息，不限购时，则同一个账号可以买多张票，也要选择对应的身份证号；当不开启实名观演时，就按每个ID可购买数对应即可。</span>
                        </FormItem>

                        <div className={`msg-title mgb15`}>演出详情</div>

                        <RuleNotice onRef={e => this.ticketRef = e} title='购票须知' tabList={data.ticket_desc || []} id={this.props.match.params.id}></RuleNotice>
                        <RuleNotice onRef={e => this.performRef = e} title='观演须知' tabList={data.perform_desc || []} id={this.props.match.params.id}></RuleNotice>

                        <FormItem label={'图文详情'}>
                            {
                                this.state.showEditor
                                    ?
                                    <Editor id={'text'} value={data.content} callback={content => this.EditorChange(content)}></Editor>
                                    :
                                    null
                            }
                        </FormItem>

                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>
                            <Button className='mgl15' onClick={this.goBack} >取消</Button>
                        </FormItem>

                    </Form>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(TicketAdd))