import React from 'react'
import { Form, Input, Select, DatePicker, Button, Pagination, Tag, Divider, Table, Tooltip, Icon, Drawer } from 'antd'
import { withRouter } from 'react-router-dom'
import { fxMemberLists, fxAuditLists, fxAudit  } from '@/api/fx'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import RefuseRemark from './refuse-remark'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class AuditLists extends React.Component{
    state = {
        searchForm:{
            name:'',
            mobile:'',
        },
        columns:[
            {
                title:'申请人信息',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={` w150 lh25`} >
                                    { rowData.name || `游客` }
                                    <i className={`icon-${rowData.sex == 1  ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.status == 4
                                            ?
                                            <Tag color={`red`} className={`mgl5`}>已冻结</Tag>
                                            :
                                            null
                                    }

                                </div>
                                <div className={`lh25`}>{ rowData.mobile || `暂无手机号` }</div>
                                <div className={`lh25`}>邀请码：{ rowData.first_user?.invite_code }</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'其他信息',
                dataIndex:'apply_info',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.apply_info && rowData.apply_info.map((item,index) => (
                                    <p className={`lh25`} key={index}>{ item.name }：{ item.value || '-' }</p>
                                ))
                            }
                        </div>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            邀请人
                            <Tooltip placement="topLeft" title={`该分销员的上级分销员`}>
                                <Icon type="question-circle"  className={`mgl5`} />
                            </Tooltip>
                        </div>
                    )
                },
                dataIndex:'first_user',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.first_user
                                    ?
                                    <div>
                                        <p className={`lh25`}>
                                            { rowData.first_user.name || '-' }
                                        </p>
                                        <p className={`lh25`}>
                                            { rowData.first_user.mobile || '-' }
                                        </p>

                                    </div>
                                    :
                                    <div>
                                        <p className={`lh25`}>无</p>
                                    </div>

                            }
                        </div>

                    )
                }
            },
            {
                title:'成为客户时间',
                dataIndex:'agent_time',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.agent_time || '-' }</p>
                    )
                }
            },
            {
                title:'申请时间',
                dataIndex:'agent_apply_time',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.agent_apply_time }</p>
                    )
                }
            },

            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleAccept(rowData)}}>同意申请</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleRefuse(rowData)}}>拒绝申请</Button>
                            </div>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        selectRows:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        fxAuditLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.lists.data,
                        total = res.data.lists.total,
                        per_page = res.data.lists.per_page;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        searchForm.status = 0;
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    handleRefuse = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.status = 2;
        this.RefuseRemark.show(res => {
            sendData.reason = res.reason;
            this.setState({
                isLoading:true
            })
            fxAudit(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('操作成功')
                            .then(() => {
                                this.handleReset();
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })
    }
    handleAccept = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.status = 1;
        this.setState({
            isLoading:true
        })
        fxAudit(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('操作成功')
                        .then(() => {
                            this.handleReset();
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    patchAccept = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择操作对象')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 1;
        this.setState({
            isLoading:true
        })
        fxAudit(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('操作成功')
                        .then(() => {
                            this.handleReset();
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    patchRefuse = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择操作对象')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 2;
        this.RefuseRemark.show(res => {
            sendData.reason = res.reason;
            this.setState({
                isLoading:true
            })
            fxAudit(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('操作成功')
                            .then(() => {
                                this.handleReset();
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }





    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>申请人姓名</span>} className={'fl'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='昵称/姓名' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem  label={<span>&emsp;</span>} colon={false} >
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                        <div className={`clearfix`}></div>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.patchAccept}>批量同意</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchRefuse}>批量拒绝</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
                <RefuseRemark onRef={ e => { this.RefuseRemark = e } }></RefuseRemark>



            </div>
        )
    }


}

export default Form.create({})(withRouter(AuditLists))
