import React, { Component } from 'react'
import { Table, Pagination, Tooltip,Icon } from 'antd'
import {pointsRank} from '@/api/data'

export default class UserPointsRanKing extends Component {
    state = {
        columns: [
            {
                title: '排名',
                dataIndex: 'ranking',
                align:'center',
                render: (text, rowData, index) => {
                    let {page,per_page}=this.state;
                    let rank=(page-1)*per_page +index +1;
                    return (
                        <p className={`lh25 `}>{rank}</p>
                    )
                }
            },
            {
                title: '客户信息',
                dataIndex: 'logo',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} >
                                    {rowData.name || `游客`}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.second_name
                                            ?
                                            <span>（{rowData.second_name}）</span>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '客户手机',
                dataIndex: 'mobile',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.mobile}</p>
                    )
                }
            },
            {
                title:
                    <>
                        累计获得积分数
                        <Tooltip placement={`right`} title={`截止查询时间末，客户积累的总积分数，包括可用积分数、过期积分数`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'total_points',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.total_points}</p>
                    )
                }
            },
            {
                title:
                    <>
                        可用积分积分数
                        <Tooltip placement={`right`} title={`截止查询时间末，客户所有实际可以使用积分数`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'points',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.points}</p>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {
            page: this.state.page,
            per_page: this.state.per_page
        }
        this.setState({
            tableLoading:true
        })

        pointsRank(sendData).then(res=>{
            if(res.code==1){
                let {current_page,data,per_page,total}=res.data.rank_list;
                this.setState({
                    page:current_page,
                    tableData:data,
                    per_page,
                    total
                })
            }

        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        return (
            <div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}
