import React, { Component } from 'react'
import { Form, Input, Button, Table, Modal, Pagination } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { teacherApi } from '@/api/educational'
import message from '@/utils/message'


class AddTeacher extends Component {

    state = {
        columns:[
            {
                title: '老师姓名',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name}</p>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.phone}
                        </p>
                    )
                }
            },
            {
                title: '教龄',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.teacher_age||"-"}
                        </p>
                    )
                }
            },
            {
                title: '职位',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.position && rowData.position.name ||"-"}
                        </p>
                    )
                }
            },
           
        ],
        tableData:[],
        modalLoading: false,
        tableLoading:false,
        page:1,
        per_page:10,
        total:0,
        teacher_name:"",
        selectedRows:[]

    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = () =>{
        let sendData = {};
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.name = this.state.teacher_name;
        sendData.status=1
        this.setState({
            tableLoading: true
        })
        teacherApi(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }


    saveData = () => {
        this.setState({
            modalLoading:false
        })
       
        this.props.success(this.state.selectedRows)
    }
    handleCancel = () => {
        this.props.close()
    }

    onChange=e=>{
        this.setState({
            teacher_name:e.currentTarget.value
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRows
              })
            }
        }

        return (

            <Modal
                title={`添加老师`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <div>
                    <Input placeholder="请输入老师姓名" value={this.state.teacher_name} onChange={this.onChange} style={{width:200}} />
                    <Button type="primary" className='mgl20' onClick={this.fetchData}>搜索</Button>
                </div>
                <div className={' mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>


            </Modal>


        )
    }
}

export default withRouter(AddTeacher)