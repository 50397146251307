import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, Table, Pagination, Modal, Tag,Tooltip, Icon, message } from 'antd'
import { groupShoppingLists ,groupShoppingEnd,groupShoppingDelete,groupShoppingPut,groupShoppingCopy} from '@/api/group-buying.js'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item;
const { TabPane } = Tabs

class GroupBuyingLists extends Component {

    state = {
        columns: [
            {
                title: '活动名称',
                dataIndex: '1',
                width:180,
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.name}</p>
                    )
                }
            },
            {
                title: '拼团价',
                dataIndex: '2',
                width:80,
                render: (text, rowData, index) => {
                    return (
                        <p> &yen;{rowData.group_price}</p>
                    )
                }
            },
            {
                title: '拼团内容',
                dataIndex: '3',
                width:180,
                render: (text, rowData, index) => {
                    return (
                       <div>
                            <p>{rowData.goods_info && rowData.goods_info.name || ''}</p>
                            <Tag color='blue'>{rowData.type ==1 ?'商品':'课程'}</Tag>
                       </div>
                    )
                }
            },
            {
                title: '成团人数',
                dataIndex: '4',
                width:80,
                render: (text, rowData, index) => {
                   
                    return (
                        <div>{rowData.open_num}人</div>
                    )
                }
            },
            {
                title: '开团数',
                width:60,
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.open_tuan_num}</p>
                    )
                }
            },
            {
                title: '成团数',
                width:60,
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.success_tuan_num}</p>
                    )
                }
            },
            {
                title: '参团订单',
                dataIndex: '7',
                width:80,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.join_tuan_num}</p>
                    )
                }
            },
            {
                title:  <Tooltip placement={`bottom`}  title={<div>
                    <p>数据指标说明</p>
                    <Divider  className='mgt10 mgb10    ' />
                    <p>开团数：该活动成功支付开团的总团数</p>
                    <p>成团数：该活动拼团成功的总团数</p>
                    <p>参团订单数：该活动成功支付的订单总数</p>
                    <p>成团订单数：该活动成团（即订单状态为已成团）的订单总数</p>
                </div>}>
                    成团订单
                <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
            </Tooltip>,
                dataIndex: '8',
                width:90,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.success_trade_num}</p>
                    )
                }
            },
            {
                title: '活动时间',
                dataIndex: '9',
                width:180,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.start_time} 至 {rowData.end_time} </p>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: 'sort',
                defaultSortOrder: '',
                sorter: true,
                width:70,
                render: (text, rowData, index) => {
                    return (
                        <div>
                        {
                            rowData.sort_edit
                                ?
                                <Input
                                    style={{ width: '80px' }}
                                    value={rowData.sort}
                                    onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                    onBlur={(e) => { this.handleField(e, rowData, index) }}
                                    data-name={'sort'}
                                />
                                :
                                <span>{rowData.sort}</span>
                        }
                        <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />
                    </div>
                    )
                }
            },
            {
                title: '活动状态',
                dataIndex: '11',
                width:80,
                render: (text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? 'orange' : rowData.status == 2 ? 'green' : ''}> {rowData.status == 1 ? '未开始' : rowData.status == 2 ? '进行中' : '已结束'}</Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '12',
                width: 190,
                fixed: 'right',
                render: (text, rowData, index) => {
                    let status = rowData.status

                    if (status == 1) {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleShare(rowData) }}>推广</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData.id) }}>编辑</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData.id) }}>删除</Button>
                            </div>
                        )
                    } else if (status == 2) {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleShare(rowData) }}>推广</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData.id) }}>编辑</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleActivityData(rowData.id) }}>活动数据</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleActivity(rowData.id) }}>结束活动</Button>
                            </div>
                        )
                    }

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleCopyActivity(rowData.id) }}>复制</Button>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleActivityData(rowData.id) }}>活动数据</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData.id) }}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        searchForm: {
            name: "",
            order_field:'',
            order_asc:''
        },
        activeStatus: '0',
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
        poster: "",
        path: ""
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.status = this.state.activeStatus

        this.setState({
            tableLoading: true
        })
        groupShoppingLists(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
        }
        this.setState({
            tableData: lists
        })
    }
    
    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }
    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = {id:rowData.id }
        sendData['key'] = name;
        sendData['value']=val

        groupShoppingPut(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }
    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchForm = this.state.searchForm;
        if (order) {
            searchForm.order_field = field;
            searchForm.order_asc = order == 'ascend' ? 'asc' : 'desc';
        } else {
            searchForm.order_field = '';
            searchForm.order_asc = '';
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }


    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val,
            page:1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleActivity = id => {
        Modal.confirm({
            title: `结束活动后，客户将不能继续参与本活动，确认提前结束吗？`,
            onOk: () => {
                groupShoppingEnd({ id }).then(res => {
                    if (res.code == 1) {

                        message.success(res.msg, 1, () => {
                            this.fetchData()
                        })
                    }
                })
            }
        })
    }

    handleDele = id => {
        Modal.confirm({
            title: `是否确认删除该活动？`,
            onOk: () => {
                groupShoppingDelete({ id }).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg, 1, () => {
                            this.fetchData()
                        })
                    }
                })
            }
        })
    }

    handleCopyActivity=id=>{
        Modal.confirm({
            title: `是否确认复制该活动？`,
            onOk: () => {
                groupShoppingCopy({ id }).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg, 1, () => {
                            this.fetchData()
                        })
                    }
                })
            }
        })
    }

    handleAdd = () => {
        this.props.history.push({
            pathname: '/group-buying/add'
        })
    }
    handleEdit = id => {
        this.props.history.push({
            pathname: '/group-buying/add/' + id
        })
    }

    handleActivityData = id => {
        this.props.history.push({
            pathname: '/group-buying/activity/' + id
        })
    }

    handleShare = row => {
        if(row.type==1){
            this.setState({
                poster: row.code_url,
                path: `/pages/item/detail?id=${row.prize_id}`
            })
        }else{
            this.setState({
                poster: row.code_url,
                path: `/pages/course/detail?course_id=${row.prize_id}`
            })
        }
        
        this.ShareModal.show()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'活动名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入活动名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem className={'fl mgl20'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>

                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增活动</Button>
                </div>
                <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={0}></TabPane>
                    <TabPane tab="进行中" key={2}></TabPane>
                    <TabPane tab="未开始" key={1}></TabPane>
                    <TabPane tab="已结束" key={3}></TabPane>
                </Tabs>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                        pagination={false}
                        scroll={{ x: 1200 }}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={this.state.poster} path={this.state.path} ></ShareModal>
            </div>
        )
    }
}

export default Form.create({})(withRouter(GroupBuyingLists))