import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { gradeApi, gradeDeleteApi } from '@/api/educational'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import AddGrade from './components/addGrade'
import PlanCourse from './components/planCourse'
import RestartGrade from './components/restartGrade'

const { TreeNode } = TreeSelect;

const FormItem = Form.Item;

class EducationalGrade extends React.Component {

    state = {
        selectRows: [],
        searchForm: {
            status: 0,
            name: ''
        },
        columns: [
            {
                title: '班级名称',
                dataIndex: 'name',

                render: (text, rowData, index) => {
                    return (
                        <div >
                            <Button type="link" onClick={() => this.handleDetail(rowData.id)}> {rowData.name}</Button>
                        </div>
                    )
                }
            },
            {
                title: '课程类目',
                dataIndex: 'course_category',

                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData.course_category && rowData.course_category.name}
                        </div>
                    )
                }
            },
            {
                title: '上课地点',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.store && rowData.store.name}</span>
                        </div>
                    )
                }
            },
            {
                title: '班级人数/总人数',
                dataIndex: 'charge_type',
                render: (text, rowData, index) => {

                    return (
                        <div>
                            {`${rowData.student_num}/${rowData.grade_num}`}
                        </div>
                    )
                }
            },
            {
                title: '已上/排课总数',
                dataIndex: 'basic_sales',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {`${rowData.teach_num}/${rowData.table_num}`}
                        </div>
                    )
                }
            },
            {
                title: '开班时间',
                dataIndex: 'start_date',

                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData.start_date}
                        </div>
                    )
                }
            },
            {
                title: '结班时间',
                dataIndex: 'end_date',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData.end_date}
                        </div>
                    )
                }
            },
            {
                title: '班级状态',
                dataIndex: 'status',
                render: (text, rowData, index) => {
                    let status_color, status_text
                    if (rowData.status == 1) {
                        status_text = "待开班"
                        status_color = "red"
                    } else if (rowData.status == 2) {
                        status_text = "开班中"
                        status_color = "green"
                    } else {
                        status_text = "已结班"
                        status_color = ""
                    }
                    return (
                        <Tag color={status_color}>{status_text}</Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            {
                                rowData.status != 3 &&
                                <>
                                    <Button type={"link"} size={"small"} onClick={() => { this.handleCourse(rowData) }}>排课</Button>
                                    <Divider type={`vertical`}></Divider>
                                    <Button type={"link"} size={"small"} onClick={() => { this.editGrade(rowData) }}>编辑</Button>
                                    <Divider type={`vertical`}></Divider>
                                </>
                            }
                            {
                                rowData.status == 3 &&
                                <>
                                   <Button type={"link"} size={"small"} onClick={() => { this.restartGrade(rowData) }}>重开班</Button>
                                   <Divider type={`vertical`}></Divider>
                                </>
                            }

                            
                            <Button type={"link"} size={"small"} onClick={() => { this.delteGrade([rowData.id]) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        isAddGrade: false,
        grade_line: "",
        edit_status: false,
        isPlanCourse: false,

    }

    componentDidMount() {
        this.fetchData()
    }


    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        gradeApi(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    closeAddGrade = () => {
        this.setState({
            isAddGrade: false
        })
    }

    closeRestartGrade = () => {
        this.setState({
            isRestart: false
        })
    }

    closePlanCourse = () => {
        this.setState({
            isPlanCourse: false
        })
    }


    handleAdd = () => {
        this.setState({
            isAddGrade: true,
            edit_status: false
        })
    }
    editGrade = row => {
        this.setState({
            isAddGrade: true,
            edit_status: true,
            grade_line: row
        })
    }
    restartGrade= row => {
        this.setState({
            isRestart: true,
            grade_line: row
        })
    }
    handleCourse = row => {
        this.setState({
            isPlanCourse: true,
            grade_line: row
        })
    }
    delteGrade = ids => {
        Modal.confirm({
            title: `删除后，已分入的班级学员都会从班内剔除需重新分班，且后续的课程都将取消，是否确认删除？`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                gradeDeleteApi({ ids })
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    handleDetail = id => {
        this.props.history.push({
            pathname: `/educational/grade-detail/` + id
        })
    }


    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name: "",
            status: 0
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }


    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <div className={'shadow-radius'}>

                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'班级名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入班级名称' style={{ width: '200px' }}  />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'班级状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请输入班级状态'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>待开班</Select.Option>
                                            <Select.Option value={2}>开班中</Select.Option>
                                            <Select.Option value={3}>已结班</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增班级</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {this.state.isAddGrade ? <AddGrade closeAddGrade={this.closeAddGrade} handleS={this.fetchData} edit_status={this.state.edit_status} grade_line={this.state.grade_line} /> : null}
                {this.state.isRestart ? <RestartGrade closeRestartGrade={this.closeRestartGrade} handleS={this.fetchData}  grade_line={this.state.grade_line} /> : null}
                {this.state.isPlanCourse ? <PlanCourse closePlanCourse={this.closePlanCourse} handleS={this.fetchData} grade_line={this.state.grade_line} /> : null}
                {this.state.isLoading ? <Loading /> : null}
                
            </div>
        )
    }


}

export default Form.create({})(withRouter(EducationalGrade))
