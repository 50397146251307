import React, { Component } from 'react'
import { Form, Input, Button, Table, Modal, Pagination } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { lotteryJoinRecord, lotteryInviteRecord, lotteryTaskRecord } from '@/api/lucky.js'
import message from '@/utils/message'


class PartakeRecord extends Component {

    state = {
        columns1: [
            {
                title: '参与时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '消耗次数',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            消耗1次
                        </p>
                    )
                }
            }
        ],
        columns2: [
            {
                title: '参与时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '被邀请人',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            呢称：{rowData.name} &nbsp;&nbsp;
                            手机号：{rowData.mobile}
                        </p>
                    )
                }
            },

        ],
        columns3: [
            {
                title: '参与时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.updated_at}</p>
                    )
                }
            },
            {
                title: '订单号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.order_sn}
                        </p>
                    )
                }
            },
            {
                title: '支付金额',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.pay_ment}
                        </p>
                    )
                }
            }
        ],
        tableData: [],
        modalLoading: false,
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        parent_mobile: "",
        selectedRowKeys: [],
        is_show: false,
        type: 0,
        searchForm: {},

    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = ({ game_id, user_id, type }) => {
        this.setState({
            searchForm: { game_id, user_id },
            page: 1,
            is_show: true,
            type
        }, () => {
            this.fetchData()
        })
    }

    fetchData = () => {
        let sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        let requestFun, type = this.state.type;
        if (type == 1) {
            requestFun = lotteryJoinRecord
        } else if (type == 2) {
            requestFun = lotteryInviteRecord
        } else if (type == 3) {
            requestFun = lotteryTaskRecord
        }

        if (requestFun) {
            this.setState({
                tableLoading: true
            })
            requestFun(sendData).then(res => {

                if (res.code === 1) {
                    let { total, per_page, data } = res.data.list
                    this.setState({
                        total, per_page, tableData: data
                    })
                }
            }).finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
        }



    }

    handleOk = () => {
        this.setState({
            is_show: false,
            tableData: []
        })
    }
    handleCancel = () => {
        this.setState({
            is_show: false,
            tableData: []
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        let { type, columns1, columns2, columns3 } = this.state;
        let title = '', columns = [];
        if (type == 1) {
            title = '抽奖记录'
            columns = columns1
        } else if (type == 2) {
            title = '邀请记录'
            columns = columns2
        } else if (type == 3) {
            title = '下单记录'
            columns = columns3
        }

        return (

            <Modal
                title={title}
                visible={this.state.is_show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                maskClosable={false}
                footer={[
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={' mgt15'}>
                    <Table
                        size={`middle`}
                        columns={columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </Modal>
        )
    }
}

export default withRouter(PartakeRecord)