import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Select, Button, Table, Pagination, Tooltip, Icon, DatePicker } from 'antd'
import { teacherData, exportTeacherData } from '@/api/data'
import { storeLists } from '@/api/store'
import Loading from '@/components/Loading'
import message from '@/utils/message';
import moment from 'moment'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class TeacherDetail extends React.Component {
    state = {
        searchForm: {
            teacher_name: '',
            grade_name: '',
            store_id: '',
            start_time: '',
            end_time: ''
        },
        columns: [
            {
                title: '老师姓名',
                dataIndex: 'logo',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.teacher_name}</p>
                    )
                }
            },
            {
                title: '班级名称',
                dataIndex: 'points_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name }</p>
                    )
                }
            },
            {
                title:
                    <>
                        总课时数
                        <Tooltip placement={`right`} title={`仅统计该班级排课的总课时数x上课总人数`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'description',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.course_total_num}</p>
                    )
                }
            },
            {
                title:
                    <>
                        已消耗课时数
                        <Tooltip placement={`right`} title={`按正常上一次课扣除对应的课时数，如发生调班情况，可能消耗可数数大于总课时数`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'draw_way',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.course_cost_num}</div>
                    )
                }
            },
            {
                title:
                    <>
                        待消耗课时数
                        <Tooltip placement={`right`} title={`班级课程未完结的情况下，才有待消耗课时，课程完结后则为0`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.course_last_num}</div>
                    )
                }
            },

        ],
        tableData: [],
        suitStores: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        storeLists: [],

    }
    componentDidMount() {
        this.fetchData()
        this.fetchStore()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading: true
        })
        teacherData(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                this.setState({
                    searchForm,
                    tableLoading: true
                })
                exportTeacherData(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '教练消费明细导出.csv';
                        CX.exportExcel(blob, fileName)
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading: false
                        })
                    })
            }
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div >
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'老师姓名'} className={'fl'}>
                                {
                                    getFieldDecorator('teacher_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入老师姓名' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'班级名称'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('grade_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入班级名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'所属门店'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('store_id')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>
                        <div className={`clearfix`}>

                            <FormItem label={'时间筛选'} className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;</span>} colon={false} className={' fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>

                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}

            </div>
        )
    }
}

export default Form.create({})(withRouter(TeacherDetail))
