import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Table, Pagination, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { roleList ,roleDelete} from '@/api/staff'
import message from '@/utils/message'

class Role extends Component {
    state = {
        columns: [
            {
                title: '角色名称',
                dataIndex: '1',

                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.name}</span>
                            {rowData.is_default?<Tag className='mgl15'>默认</Tag>:null}
                        </div>
                    )
                }
            },
            {
                title: '描述',
                dataIndex: '2',
                width: "40%",
                render: (text, rowData, index) => {
                    return (
                        <div> {rowData.desc || "-"}</div>
                    )
                }
            },
            {
                title: '员工数量',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div> {rowData.staff_num}</div>
                    )
                }
            },

            {
                title: '操作',
                dataIndex: 'opts',

                render: (text, rowData, index) => {


                    return (
                        <div className={'btn-row'}>

                            <Button type={"link"} size={"small"} onClick={() => { this.editRole(rowData.id)}}>编辑</Button>
                           
                            {rowData.is_default?null: <Divider type={`vertical`}></Divider>}
                            {rowData.is_default?null: <Button type={"link"} size={"small"} onClick={() => { this.deleRole(rowData.id) }}>删除</Button>}
                            
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        roleList(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    editRole=id=>{
        this.props.history.push({
            pathname: `/staff/role-add/`+id
        })
    }
    deleRole=id=>{
        Modal.confirm({
            title: `是否确认要删除？删除后无法恢复，请谨慎操作！`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                roleDelete({ id})
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    handleAdd = () => {
        this.props.history.push({
            pathname: `/staff/role-add`
        })
    }


    render() {
        return (
            <div className='shadow-radius'>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增角色</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(Role)