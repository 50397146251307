import React, { Component } from 'react'
import { Form, Input, Button, Table, Modal, Pagination, Tag, Divider } from 'antd'
import moment from 'moment'
import Deliver from '@/views/order/components/deliver'
import { withRouter } from 'react-router-dom'
import { lotteryReward } from '@/api/lucky.js'
import {  deliverTrade } from '@/api/order'
import message from '@/utils/message'


class SeePrize extends Component {

    state = {
        columns: [
            {
                title: '领奖时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.send_time}</p>
                    )
                }
            },
            {
                title: '奖品类型',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {['优惠券', '积分', '微信零钱', '实物'][rowData.type - 1] || ''}
                        </p>
                    )
                }
            },
            {
                title: '奖品内容',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>

                            <div className={`mgr10`}> {rowData.reward_name}</div>
                            {
                                rowData.type == 4 ? <img src={rowData?.reward?.prize_img} alt="" width={60} /> : null
                            }
                        </div>
                    )
                }
            },
            {
                title: '奖品状态',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    if (rowData.type == 4 && rowData.trade && (rowData.trade.post_type == 1) && (rowData.trade.user_address_id == 0)) {
                        return <Tag color="orange">未领奖</Tag>
                    }

                    return <Tag color="green">已领奖</Tag>
                }
            },
            {
                title: '操作',
                dataIndex: '5',
                render: (text, rowData, index) => {

                    if (rowData.type == 4) {
                        if (rowData.trade && (rowData.trade.post_type == 2)) {
                            return <Button type={"link"} size={"small"}>查看核销码</Button>
                        } else if (rowData.trade && (rowData.trade.post_type == 1) && rowData.trade.user_address_id &&(!rowData.trade.express_sn)) {
                            return (
                                <div className={'btn-row'}>
                                    <Button type={"link"} size={"small"} onClick={() => { this.handleSeeAddress(rowData.trade) }}>查看快递地址</Button>
                                    <Divider type={`vertical`}></Divider>
                                    <Button type={"link"} size={"small"} onClick={() => { this.handleMark(rowData) }}>标记发货</Button>
                                </div>
                            )
                        }

                    }

                    return (
                        <p>-</p>
                    )
                }
            }
        ],
        tableData: [],
        modalLoading: false,
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        searchForm: {},
        is_show: false,
       

    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = ({ game_id, user_id }) => {
        this.setState({
            searchForm: { game_id, user_id },
            page: 1,
            is_show: true
        }, () => {
            this.fetchData()
        })
    }
    onChange = e => {
        console.log(e.currentTarget.value);
        this.setState({
            ems_sn: e.currentTarget.value
        })
    }
    handleMark = item => {
        let sendData = {}
        this.Deliver.show(res => {
            sendData.order_sn =item.trade.order_sn;
            sendData.express_id = res.express_id || '';
            sendData.express_sn = res.express_sn || '';
          
            deliverTrade(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记发货成功')
                    }
                })
               

        })
    }

    handleSeeAddress = item => {

        Modal.info({
            title: '查看快递地址',
            content: <div>
                <p>收件人：{item.name}</p>
                <p>收件人手机号：{item.phone}</p>
                <p>收货地址：{item.user_address}</p>
            </div>
        })
    }

    fetchData = () => {
        let sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        this.setState({
            tableLoading: true
        })
        lotteryReward(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }


    handleOk = () => {
        this.setState({
            is_show: false
        })
    }
    handleCancel = () => {
        this.setState({
            is_show: false
        })
    }



    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {


        return (

            <Modal
                title={`查看奖品`}
                visible={this.state.is_show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                maskClosable={false}
                footer={[
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={' mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <Deliver onRef={ e => { this.Deliver = e } }></Deliver>      
            </Modal>
        )
    }
}

export default withRouter(SeePrize)