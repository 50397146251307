import React from 'react'
import { Form, Input, Button, Icon, Modal, Table, Tag, Divider, Pagination } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { formSubmitUser, submitUserDelete, submitUserExport } from '@/api/custom-form'
import { ICON_FONT_URL } from '@/constants/iconFont'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import LogDetail from './components/LogDetail'
import CX from '@/utils/CX'
import '@/assets/css/design/lists.scss'
const FormItem = Form.Item;
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})
class CustomFormLog extends React.Component{
    state = {
        columns:[
            {
                title:'用户信息',
                dataIndex:'nick_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>用户昵称：{rowData.name || '--'}</p>
                            <p>&emsp;手机号：{rowData.mobile || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'用户头像',
                dataIndex:'head_img_url',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60}/>
                        </div>
                    )
                }
            },
            {
                title:'提交时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at || '-' }</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDetail(rowData)}}>查看详情</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            nick_name:'',
            mobile:''
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:'',
        CXModules:'',
        isDetail:false
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.id=this.props.match.params.id
        sendData.page = this.state.page;
        sendData.per_page = 10;
        this.setState({
            isLoading:true
        })
        formSubmitUser(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData,
                        total,
                        per_page
                    })

                }
            })
            .finally(() =>  {
                this.setState({
                    isLoading:false
                })
            })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname: `/custom-form/add/${rowData.id}`
        })
    }
    handleDetail = rowData => {
       
        let layout=JSON.parse(rowData.layout)
        this.setState({
            isDetail:true,
            CXModules:layout.CXModules || []
        })
    }
    handleDelete = rowData => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`确定要删除这条记录吗？`,
            okType:`danger`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                submitUserDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('删除成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                sendData = values;
                sendData.id = this.props.match.params.id;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                submitUserExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '问卷调查明细.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'用户名称'} className={'fl'}>
                            {
                                getFieldDecorator('nick_name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索用户名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'手机号'} className={'fl mgl20'}>
                            {
                                getFieldDecorator('mobile',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索手机号'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
                {this.state.isDetail? <LogDetail CXModules={this.state.CXModules} close={()=>this.setState({isDetail:false})} />:null}
            </div>
        )
    }

}
export default Form.create({})(withRouter(CustomFormLog))
