import React from 'react'
import { Form, Input, Button, Icon, Modal, Table, Tag, Divider, Pagination } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { formLists, formDelete, formSave } from '@/api/custom-form'
import { ICON_FONT_URL } from '@/constants/iconFont'
import ShareModal from '@/components/shareModal/shareModal'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import '@/assets/css/design/lists.scss'
const FormItem = Form.Item;
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})
class CustomFormLists extends React.Component{
    state = {
        columns:[
            {
                title:'问卷编号',
                dataIndex:'file_no',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.file_no || '--'}</p>
                    )
                }
            },
            {
                title:'问卷名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'提交人数',
                dataIndex:'address',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.user_question_count}</p>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at || '-' }</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? `green` : `orange`}>{rowData.status == 1 ? '已启用' : '已停用'}</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'30%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            {
                               rowData.user_question_count? 
                               null:
                               <>
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button> 
                                    <Divider type={`vertical`}></Divider>
                               </>
                            }
                           
                            {
                                rowData.status == 1
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData,0)}}>停用</Button>
                                    :
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData,1)}}>启用</Button>
                            }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>分享</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleLog(rowData)}}>查看提交用户</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:'',
            file_no:'',
            start_time:'',
            end_time:''
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:''
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = 10;
        this.setState({
            isLoading:true
        })
        formLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData,
                        total,
                        per_page
                    })
                }
            })
            .finally(() =>  {
                this.setState({
                    isLoading:false
                })
            })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/custom-form/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname: `/custom-form/add/${rowData.id}`
        })
    }
    handleLog = rowData => {
        this.props.history.push({
            pathname: `/custom-form/log/${rowData.id}`
        })
    }
    handleDelete = rowData => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`确定要删除这个问卷吗？`,
            okType:`danger`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                formDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('删除成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleStatus = (rowData,status) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        Modal.confirm({
            title:`是否${ status == 1 ? '启用':'停用' }该问卷？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                sendData.status = status;
                formSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleShare = row => {

        this.setState({
            poster:row.code_url,
            path:`/pages/custom-form/index?id=${row.id}`
        })
        this.ShareModal.show()
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'问卷名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索问卷名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'问卷编号'} className={'fl mgl20'}>
                            {
                                getFieldDecorator('file_no',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索问卷编号'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Link target={'_blank'} to={'/custom-form/add'} >
                       <Button type={'primary'}  icon={'plus'}>创建问卷</Button>
                    </Link>
                    
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(CustomFormLists))
