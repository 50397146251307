import React from 'react'
import { Button, Table, Pagination } from 'antd'
import { fansLists } from '@/api/miniProgram'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Cookie from 'js-cookie'
import { withRouter } from 'react-router-dom'

class OfficialFansLists extends React.Component{
    state = {
        columns:[
            {
                title:'头像',
                dataIndex:'avatar',
                render:(text, rowData, index) => {
                    return (
                        <img src={ rowData.user?.head_img_url ||  rowData.avatarurl || `https://img.cxkoo.com/chengxuan/1/2021-10-13/eb909a3a165f33d59f136339eefb3c78.png`} width={60} alt=""/>
                    )
                }
            },
            {
                title:'昵称',
                dataIndex:'nick_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.user?.name || rowData.nickname || '--'}</p>
                    )
                }
            },
            {
                title:'APPID',
                dataIndex:'app_id',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.app_id || ''}</p>
                    )
                }
            },
            {
                title:'openid',
                dataIndex:'openid',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.openid || ''}</p>
                    )
                }
            },
            {
                title:'导入时间',
                dataIndex:'subscribe_time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.subscribe_time}</p>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page
        this.setState({
            tableLoading:true
        })
        fansLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let fans_list = res.data.fans_list.data,
                        per_page = res.data.fans_list.per_page,
                        total = res.data.fans_list.total;
                    this.setState({
                        tableData:fans_list,
                        per_page,total
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        return(
            <div className={'shadow-radius'}>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }

            </div>
        )
    }
}

export default withRouter(OfficialFansLists)
