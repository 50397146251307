import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon, Checkbox,Popover } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import { couponLists } from '@/api/coupon'
import {  createLabelGroup } from '@/api/user'

const FormItem = Form.Item;

 class CouponList extends Component {
    state={
        visible:false,
        modalLoading:false,
        selectedRowKeys:[],
        storeLists:[],
        popVisible:false,
        columns:[
            {
                title: '优惠券名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name}</p>
                    )
                }
            },
            {
                title: '优惠券类型',
                dataIndex: 'coupon_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '限领次数',
                dataIndex: 'last_num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.last_num}</p>
                    )
                }
            },
            {
                title: '适用门店',
                dataIndex: 'suit_store_name',
                render: (text, rowData, index) => {
                    return ( <div className={`lh25`}>
                            {
                                rowData.suit_store_type === 1
                                    ?
                                    '全部'
                                    :
                                    <span className={`fi-link`}>{ rowData.suit_store_name }</span>
                            }
                        </div>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex: 'effect_time',
                render: (text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            }
        ],
        tableData:[],
        callback:null,
        total:0,
        per_page:20,
        page:1,
        searchForm:{
            name:'',
            coupon_type:'',
        },
    }

    componentDidMount() {
        this.props.onRef(this)
    }


    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }

    fetchData = (row) => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        couponLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.coupon_list.data,
                        total = res.data.coupon_list.total,
                        per_page = res.data.coupon_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                    let arr = [];
                    if(row){
                        row.forEach((item,index)=>{
                            arr.push(item.id)
                        })
                        this.setState({
                            selectedRowKeys:arr
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }    

    show = (row,callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        
        if(!row){
            this.setState({
                selectedRowKeys:null
            });
        }
        this.fetchData(row)
    }

    saveData=()=>{
        const { sendFn } = this.props;
        let resData = this.state.selectedRowKeys
        let data = this.state.tableData
        let arr = [];
        resData.forEach((item,index)=>{
            const element =  data.find(items=> items.id === item);
            element.num = 1;
            arr.push(element)
        })
        sendFn(arr)
        this.setState({
            visible:false
        })

    }

    handleDel = (index) =>{
        // let resData = this.state.tableData;
        // resData.label_value.splice(index,1);
        // this.setState({
        //     tableData:resData
        // })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    getInputValue = e => {
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_name = val;
        this.setState({
            tableData: datas
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
      
    }

    getLabelInput = (e,index) =>{
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_value[index].name = val;
        this.setState({
            tableData: datas
        })
    }

    handlePoints = () => {
        this.setState({
            popVisible: true
        })
    }

    handleSavePoints = () => {
        this.setState({
            popVisible: false
        })
    }

    closePop = () => {
        this.setState({
            popVisible: false
        })
    }

    

    onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        this.setState({
            selectedRowKeys:newSelectedRowKeys
        })
    };

    render() {
        const { getFieldDecorator } = this.props.form
        const tableData = this.state.tableData || []
        const selectedRowKeys = this.state.selectedRowKeys
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Modal
            title={"选择优惠券"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={1300}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <div>
            <div className={'content-head mgb15'}>
                    <Form className={'search-form flex aglin-center'}>
                        <div className={`clearfix`}>
                            <FormItem label={'优惠券类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('coupon_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>满减券</Select.Option>
                                            <Select.Option value={2}>折扣券</Select.Option>
                                            <Select.Option value={3}>兑换券</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>优惠券名称</span>} className={'fl'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入优惠券名称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className='mgt15'>
                    <Table
                        rowSelection={rowSelection}
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={tableData}
                        rowKey={record => record.id}
                        style={{ width:'100%' }}
                        pagination={false}
                    ></Table>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        )
    }
}

export default Form.create({})(CouponList)
