import React from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Icon, Tooltip, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { addRemark, maxRefundMoney, orderPartRefund, bookingOrder, exportSpaceOrder, AcceptOrder, acceptRefund, pickUpCode } from '@/api/order'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import Remark from './components/remark'
import ItemRefund from './components/item-refund'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

//订单类型 1-普通下单(购物车) 2-优惠券 3-自助买单 4-扫码点餐 5-ktv预订订单 10-场馆预订
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
class CourtBookLists extends React.Component{
    state = {
        activeStatus:'0',
        searchForm:{
            order_sn:'',
            goods_name:'',
            pay_type:'',
            user_name:'',
            user_mobile:'',
            receive_user_name:'',
            receive_user_mobile:'',
            trade_type:'',
            post_type:'',
            order_store_id:'',
            time_type:'',
            start_time:'',
            end_time:''
        },
        tableData:[],
        storeLists:[],
        isLoading:false,
        noData:false,
        page:1,
        total:0,
        per_page:10,
        lastRefundMoney:0,
        payMent:0,
        refundMoney:0
    }

    componentDidMount() {
        if(this.props.match.params.status){
            let status = this.props.match.params.status
            this.setState({
                activeStatus:String(status)
            },() => {
                this.fetchData()
            })
        }else {
            this.fetchData()
        }
        this.fetchStore();
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        sendData.trade_type = 10
        this.setState({
            isLoading:true
        })
        bookingOrder(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page
                    })
                    if(lists.length < 1){
                        this.setState({
                            noData:true
                        })
                    }else {
                        this.setState({
                            noData:false
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {})
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                sendData.status = this.state.activeStatus;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                exportSpaceOrder(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '场馆预约订单导出.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })



            }
        })
    }
    handleDeliver = () => {}
    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    handleDetail = (rowData) => {
        this.props.history.push({
            pathname:`/order/detail/${rowData.order_sn || ''}`
        })

    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleRemark = (rowData) => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = rowData.order_sn;
            if(!sendData.remark){ return false };
            this.setState({
                isLoading:true
            })
            addRemark(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })

        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handlePartRefund = (rowData) =>  {
        let sendData = {};
        sendData.refund_id = rowData.refund_id || ''
        this.setState({
            isLoading:true
        })
        maxRefundMoney(sendData)
            .then(res => {
                if(res.code == 1){
                    let last_refund_money = res.data.last_refund_money,
                        pay_ment = res.data.pay_ment,
                        refund_money = res.data.refund_money;
                    this.setState({
                        lastRefundMoney:last_refund_money,
                        payMent:pay_ment,
                        refundMoney:refund_money
                    },() => {
                        this.ItemRefund.show((values) =>  {
                            let postData = values;
                            postData.refund_id = rowData.refund_id || ''
                            this.setState({
                                isLoading:true
                            })
                            orderPartRefund(postData)
                                .then(result => {
                                    if(result.code == 1){
                                        message.success('操作成功')
                                            .then(() => {
                                                this.fetchData();
                                            })
                                    }
                                })
                                .finally(() => {
                                    this.setState({
                                        isLoading:false
                                    })
                                })
                        })
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleAccept = (rowData) => {
        let sendData = {}
        sendData.order_sn = rowData.order_sn;
        this.setState({
            isLoading:true
        })
        AcceptOrder(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('接单成功')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleRefuse = (rowData) => {
        let sendData = {}
        sendData.order_sn = rowData.order_sn;
        Modal.confirm({
            title:`是否对该订单进行拒单？`,
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                acceptRefund(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handlePickUp = (rowData) => {
        let sendData = {};
        sendData.order_sn = rowData.order_sn;
        sendData.use_code = rowData.use_code || ``;
        Modal.confirm({
            title:'是否对这笔订单进行核销？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pickUpCode(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('核销成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })


    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <Remark onRef={ e => { this.Remark = e } }></Remark>
                <ItemRefund
                    onRef={ e => { this.ItemRefund = e } }
                    refundMoney={ this.state.refundMoney }
                    lastRefundMoney={ this.state.lastRefundMoney }
                    payMent = { this.state.payMent }
                ></ItemRefund>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;商户单号</span>} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;客户昵称</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称/ID' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;客户手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;支付方式</span>} className={'fl'}>
                                {
                                    getFieldDecorator('pay_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={2}>微信支付</Select.Option>
                                            <Select.Option value={4}>储值卡余额支付</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;预约门店</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('order_store_id')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;订单来源</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('source')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={``}>全部</Select.Option>
                                            <Select.Option value={1}>小程序</Select.Option>
                                            <Select.Option value={2}>商家后台</Select.Option>

                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;时间筛选</span>} className={' fl'}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={1}>下单时间</Select.Option>
                                            <Select.Option value={2}>预订时间</Select.Option>
                                            <Select.Option value={3}>成交时间</Select.Option>
                                        </Select>
                                    )
                                }

                            </FormItem>
                            <FormItem className={' fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={`0`}></TabPane>
                    <TabPane tab="待付款" key={`1`}></TabPane>
                    <TabPane tab="待接单" key={`2`}></TabPane>
                    <TabPane tab="待使用" key={`3`}></TabPane>
                    <TabPane tab="退款中" key={`4`}></TabPane>
                    <TabPane tab="交易成功" key={`5`}></TabPane>
                    <TabPane tab="交易关闭" key={`6`}></TabPane>
                </Tabs>
                <div className={''}>
                    <table className="cxtables cxtables-sku">
                        <colgroup>
                            <col width={`18%`} />
                            <col width={`8%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`8%`} />
                            <col width={`8%`} />
                        </colgroup>
                        <thead>
                        <tr>
                            <td>预订信息</td>
                            <td>场次价格/数量</td>
                            <td>客户</td>
                            <td>预订金额</td>
                            <td>预留信息</td>
                            <td>预订门店</td>
                            <td>订单状态</td>
                            <td>操作</td>
                        </tr>
                        </thead>
                        {
                            this.state.noData ?
                                <tbody>
                                    <tr>
                                        <td colSpan={9}>
                                            <div className={`lh25 txtCenter`}>暂无订单数据</div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                null
                        }

                    </table>
                    {
                        this.state.tableData.map((item,index) => (
                            <table className="cxtables cxtables-sku mgt10" key={index}>
                                <colgroup>
                                    <col width={`18%`} />
                                    <col width={`8%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`8%`} />
                                    <col width={`8%`} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <td colSpan={8} className={`ftnormal clearfix`}>
                                        <Tag color={`#ff0000`}>{ item.trade_type_name }</Tag>
                                        <span>商户单号：<span>{ item.order_sn }</span></span>
                                        <Icon type="file-text" className={`mgl5 cursor`} onClick={ () => { this.handleCopy(item.order_sn) } } />
                                        <span className={`mgl10`}>下单时间：<span>{ item.created_at }</span></span>
                                        {
                                            item.remark
                                                ?
                                                <Tooltip placement="left" title={`${item.remark}`}>
                                                    <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/6b8f2e533456302124ee110cd006b18f.png" width={20} className={`fr cursor`} alt=""/>
                                                </Tooltip>
                                                :
                                                <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/610b1cfaa3f361987ea789c8cacd7a8a.png" width={20} className={`fr cursor`} alt="" onClick={ () => { this.handleRemark(item) } }/>
                                        }
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={`noPadding bd-right`} colSpan={2}>
                                        <div className={`clearfix`}>
                                            <div className={`clearfix bd-bottom`}>
                                                <div className={`flex jusify-start align-center td-item fl`} style={{ width:'75%' }}>
                                                    <div className={`mgl10`}>
                                                        {
                                                            item.space_info.space_use_type == 2 && item.space_info.space_mix_info
                                                                ?
                                                                <div className={`lh25`}>
                                                                    <p className={`lh25`}>时间：{ item.book_info?.book_date || '-' }</p>
                                                                    <p className={`lh25`}>
                                                                        时段：{ `${item.space_info?.space_mix_info?.start_time}~${item.space_info?.space_mix_info?.end_time}` }
                                                                        {
                                                                            item.pay_ment > 0
                                                                                ?
                                                                                <span> &nbsp;价格：&yen;{ `${item.pay_ment}` }</span>
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>

                                                                </div>
                                                                :
                                                                <div className={`lh25`}>时间：{ item.book_info?.book_date || '-' }</div>
                                                        }
                                                        {
                                                            item.book_info.book_time && item.book_info.book_time.length
                                                                ?
                                                                item.book_info.book_time.map((child,childKey) => (
                                                                    <div className={`lh25`} key={childKey}>
                                                                        <span>{ childKey == 0 ? '时段：' : <span>&emsp;&emsp;&emsp;</span> }</span>{ child.time_period }
                                                                        {
                                                                            child.price > 0
                                                                            ?
                                                                                <span> 价格：&yen;{ child.price }</span>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                ))
                                                                :
                                                                null

                                                        }
                                                        <div className={`lh25`}>场地：{ item.book_info?.space_type_name || '-' }-{ item.book_info?.space_name || '-' }</div>
                                                        <div className={`lh25`}>用途：{ item.book_info?.use_type_name || '-' }</div>

                                                    </div>
                                                </div>
                                                <div className={` td-price fr`}>
                                                    <div className={`lh25 txtRight`}></div>
                                                    <div className={`lh25 txtRight`}></div>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.name }</div>
                                        <div className={`lh25`}>{ item.phone }</div>
                                    </td>
                                    <td>
                                        {
                                            [0,6,7,8].includes(item.trade_status)
                                                ?
                                                <div className={`lh25 ft-bold`}>待支付：￥{ item.pay_ment }</div>
                                                :
                                                <div className={`lh25 ft-bold`}>支付合计：￥{ item.pay_ment }</div>
                                        }
                                        {
                                            item.space_info.is_buy_insurance == 1
                                                ?
                                                <div className={`lh25 `}>含保险：￥{ item.space_info.safety_money }</div>
                                                :
                                                null

                                        }
                                        {
                                            item.trade_extends && item.refund_price > 0
                                                ?
                                                <div className={`lh25 colorRed`}>退款金额：￥{ item.refund_price || '-' }</div>
                                                :
                                                null
                                        }
                                        <div className={`lh25`}>支付方式：{ item.pay_type_name || '-' }</div>
                                        <div className={`lh25`}>支付时间：{ item.pay_time || '-' }</div>

                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.name }</div>
                                        <div className={`lh25`}>{ item.phone }</div>
                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.store ? item.store.name : '-' }</div>
                                        <div className={`lh25`}>来源：{ item.source == 1 ? '小程序' : '后台' }</div>
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                [1,2].includes(item.trade_status)
                                                    ?
                                                    <Tag color="green">{ item.trade_status_name }</Tag>
                                                    :
                                                    null
                                            }
                                            {
                                                [0,4,6,7,8].includes(item.trade_status)
                                                    ?
                                                    <Tag>{ item.trade_status_name }</Tag>
                                                    :
                                                    null

                                            }
                                            {
                                                [3,5,9,10,11,12].includes(item.trade_status)
                                                    ?
                                                    <Tag color="orange">{ item.trade_status_name }</Tag>
                                                    :
                                                    null

                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className={'btn-row'}>
                                            <Button type={"link"} size={"small"} style={{width:'80px'}} className={`mgb5`} onClick={() => {this.handleDetail(item)}}>查看详情</Button>
                                            {
                                                item.trade_status == 1
                                                    ?
                                                    <Button type={"link"} size={"small"} style={{width:'80px'}} className={`mgb5`} onClick={() => {this.handlePickUp(item)}}>在线核销</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                item.trade_status == 9
                                                    ?
                                                    <div>
                                                        <Button type={"link"} size={"small"} style={{width:'80px'}} className={`mgb5`} onClick={() => {this.handleAccept(item)}}>确认接单</Button>
                                                        <Button type={"link"} size={"small"} style={{width:'80px'}} className={`mgb5`} onClick={() => {this.handleRefuse(item)}}>拒绝接单</Button>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        ))
                    }

                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }


            </div>
        )
    }


}

export default Form.create({})(withRouter(CourtBookLists))
