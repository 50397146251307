/**
 * 工具函数CX
 *
 */
import copy from 'copy-to-clipboard'
import { notification } from 'antd'
import history from '@/utils/history'
import store from '@/redux/store'

let socket = '';
const CX = {
    /**
     * 异步任务队列
     * @param {Array}
     * @return Promise
     */
    startQueue : (queues) => {
        let sequence = Promise.resolve();
        queues.forEach(item => {
            sequence = sequence.then(item)
        })
        return sequence
    },
    genUUID: () => {
        // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    getUrlParams : ( url = '' ) => {
        const _url = url || window.location.href;
        const _urlParams = _url.match(/([?&])(.+?=[^&]+)/igm);
        return _urlParams ? _urlParams.reduce((a, b) => {
            const value = b.slice(1).split('=');
            a[value[0]] = value[1]
            return a;
        }, {}) : {};
    },

    diff : (arr1, arr2) => {
        let newArr1=arr1.filter(value=>arr2.indexOf(value)==-1),
            newArr2=arr2.filter(value=>arr1.indexOf(value)==-1);
        return newArr1.concat(newArr2)
    },
    debounce:(func, wait, immediate) => {
        let timeout, args, context, timestamp, result;

        const later = function() {
            // 据上一次触发时间间隔
            const last = +new Date() - timestamp;

            // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                }
            }
        };

        return function(...args) {
            context = this;
            timestamp = +new Date();
            const callNow = immediate && !timeout;
            // 如果延时不存在，重新设定延时
            if (!timeout) timeout = setTimeout(later, wait);
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }

            return result;
        };
    },
    downloadImage:(imgUrl,name) => {
        let image = new Image()
        image.setAttribute("crossOrigin",'anonymous')
        image.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            let context = canvas.getContext('2d')
            context.drawImage(image,0,0,image.width,image.height)
            let url = canvas.toDataURL('image/png'),
                a = document.createElement('a'),
                event = new MouseEvent('click');
            a.href = url;
            a.download = name || '未命名'
            a.dispatchEvent(event)
        }
        image.onerror = err => {
            console.log(err)
        }
        image.src = imgUrl
    },
    exportExcel:(fileSteam,fileName) => {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(fileSteam)
        link.download = fileName || '未命名.xlsx' //下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    },
    copyTxt:( text = '' ) => {
        if(!text){ return };
        copy(text)
    },
    dealPrice:( price = 0 ) => {
        price =parseFloat(price).toFixed(2);
        return [price.split('.')[0],price.split('.')[1]]
    },
    groupBy:(list,key) => {
        const obj = {};
        list.map(item => {
            if (!obj.hasOwnProperty(item[key])) { //如果不存在这个属性
                obj[item[key]] = [];
            }
            obj[item[key]].push(item);
        });
        return obj;
    },
    openSocket:() => {
        // 连接服务端，如果是https请求则必须使用域名请求，证书是要验证域名的
        let shop = store.getState().shop ? store.getState().shop : {},
            shop_id = shop.id || '';
        if(window.io){
            console.log('11111111111111111111111111111')
            if(socket){ return ;}
            let io = window.io;
            socket = io(`http://${document.domain}:6001`);
            //socket = io('https://mp.cxshope.com:6001');
            // 触发服务端的chat message事件
            socket.emit('chat message', '这个是消息内容...');
            // 服务端通过emit触发客户端的chat message from server事件
            socket.on('trade_notice', function(data){
                if(data.shop_id == shop_id){
                    let params= {
                        message: '有新订单啦',
                        description:`您有一笔新的${data.trade_type_name || ''}订单，请注意查收`,
                        onClick:function (e) {
                            history.push(`/order/detail/${data.order_sn || ''}`)
                        }
                    }
                    notification.open(params)
                }
            });
        }else {
            console.log('socket初始化失败')
        }
    },
    closeSocket:() => {
        if(socket && socket.disconnect){
            socket.disconnect()
        }
        socket = null;
    },
    color16:()=>{
        const r=Math.floor(Math.random()*256);
        const g=Math.floor(Math.random()*256);
        const b=Math.floor(Math.random()*256);
        const color=`rgb(${r},${g},${b})`
      
        return color
    }
};

export default CX

