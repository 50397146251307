import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Tabs, Table, Pagination, Button, message } from 'antd';
import {liveSaveDetail,liveSendNotice} from '@/api/livestream';

const { TabPane } = Tabs;

 class CalendarDetail extends Component {

    state = {
        columns: [
            {
                title: '商品信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <p>{rowData.name || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '分组',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p> { rowData.group?.name ||'' }</p>
                    )
                }
            },
            {
                title: '赠品',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            {
                                rowData.present_img ? <img src={rowData.present_img} alt="" width={60} height={60} /> : null
                            }
                            <div className={`mgl10`}>
                                <p>{rowData.present_info || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '优惠/到手价',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p> { rowData.price}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '9',
                render: (text, rowData, index) => {
                    if(rowData.is_notice){
                        return <p></p>
                    }

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleNotice(rowData)}}>发送上播提醒</Button>
                        </div>
                    )
                }
            },

        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 20,
        total: 0,
        groupLists: [],
        activeKey: '0',
        detail:{}

    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData=()=>{
        this.setState({
            tableLoading:true
        })
        let sendData={
            id:this.props.match.params.id,
            page:this.state.page,
            per_page:this.state.per_page,
            group_id:this.state.activeKey 
        }

        liveSaveDetail(sendData).then(res=>{
            if(res.code == 1){
                let detail=res.data.detail;
                let {current_page,data,per_page ,total}=res.data.goods_list;
                let groupLists=res.data.group_list;
                
                this.setState({
                    tableData:data,
                    groupLists,
                    page:current_page,
                    per_page:per_page,
                    total,
                    detail
                })
            }
        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleChange = key => {
        this.setState({
            activeKey: key
        },()=>{
            this.fetchData()
        })
    }

    handleNotice=row=>{

        liveSendNotice({live_goods_id:row.id,live_id:row.live_times_id}).then(res=>{
            if(res.code==1){
                message.success(res.msg)
                this.fetchData()
            }
        })
    }

    render() {
        let { groupLists, activeKey ,detail} = this.state
        return (
            <div className={'shadow-radius'}>
                <div className={`msg-title mgb15`}>直播详情</div>
                <Row>
                    <Col span={7}>直播标题：{detail.title}</Col>
                    <Col span={7}>商品数量：{detail.goods_count}件</Col>
                    <Col span={7}>开播时间：{detail.start_time}</Col>
                </Row>
                <div className={`msg-title mgb15 mgt15`}>直播商品</div>
                <Tabs onChange={this.handleChange} type="card" activeKey={activeKey}>
                        <TabPane tab={'全部'} key={'0'}></TabPane>
                    {
                        groupLists.map(val => {
                            return (
                                <TabPane tab={val.name} key={val.id}></TabPane>
                            )
                        })
                    }
                </Tabs>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CalendarDetail)