import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Modal, Tag, TreeSelect, Drawer, Icon, message } from 'antd'
import moment from 'moment'
import HxStore from './components/HxStore'
import { jizanReward, jizanSendWallet,exportReward } from "@/api/collect-likes.js"
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class CollectLikesWinners extends Component {

    state = {
        columns: [
            {
                title: '中奖客户信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.name || ''}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '集赞总数',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.likes_num}</p>
                    )
                }
            },
            {
                title: '奖品类型',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    let type_name
                    switch (rowData.type) {
                        case 1:
                            type_name = "优惠券"
                            break;
                        case 2:
                            type_name = "积分"
                            break;
                        case 3:
                            type_name = "微信零钱"
                            break;
                        case 4:
                            type_name = "实物奖"
                            break;

                        default:
                            type_name = "-"
                            break;
                    }

                    return (
                        <p> {type_name}</p>
                    )
                }
            },
            {
                title: '奖品内容',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.reward_name || '-'}</p>
                    )
                }
            },
            {
                title: '兑奖时间',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.send_time || '-'}</p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? "green" : "orange"}> {rowData.status == 1 ? "已兑换" : "未兑换"}</Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '7',
                width: '100px',
                render: (text, rowData, index) => {
                    let status = rowData.status, type = rowData.type, trade = rowData.trade

                    if (type == 3 && status == 0) {
                        return <Button type={"link"} size={"small"} onClick={() => { this.handleWechat(rowData) }}>补发零钱</Button>
                    }

                    if (type == 4 && status == 1) {

                        if (trade.post_type == 1) {
                            return <Button type={"link"} size={"small"} onClick={() => { this.handleAddress(rowData) }}>查看邮寄地址</Button>
                        }

                        if(trade.trade_status==1){
                            return <Button type={"link"} size={"small"} onClick={() => { this.handleHx(rowData) }}>查看核销码</Button>
                        }

                    }

                    return <div>-</div>
                }
            }
        ],
        searchForm: {
            mobile: "",
            status: 0,
            start_time: "",
            end_time: ""
        },
        storeList: [],
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
        is_hx: false,
        join_id: "",
        trade:"",
        isLoading:false
    }

    componentDidMount() {
        this.fetchData()

    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.id = this.props.match.params.id

        this.setState({
            tableLoading: true
        })
        jizanReward(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }



    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleExport=()=>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let sendData = values;
                sendData.id = this.props.match.params.id
                this.setState({
                    isLoading:true
                })
                exportReward(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '获奖名单.csv';
                        CX.exportExcel(blob, fileName)
                    }).finally(()=>{
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        searchForm.status = 0

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleWechat = row => {
        Modal.confirm({
            title: `确认发放零钱吗？`,
            onOk: () => {
                jizanSendWallet({ join_id: row.id }).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                        this.fetchData()
                    }
                })
            }
        })
    }

    handleAddress = row => {
        this.setState({
            is_address: true,
            trade:row.trade
        })
    }
    handleAddressCancel = () => {
        this.setState({
            is_address: false
        })
    }

    handleHx = row => {
        this.setState({
            is_hx: true,
            join_id: row.id,
            trade:row.trade
        })
    }
    handleHxCancel = () => {
        this.setState({
            is_hx: false
        })
    }




    render() {
        let trade=this.state.trade || {}
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'中奖手机号'} className={'fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'状态'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>已兑换</Select.Option>
                                            <Select.Option value={2}>未兑换</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem  label={<span>&emsp;兑奖时间</span>} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem  className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>


                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                <Modal
                    title="邮寄地址"
                    visible={this.state.is_address}
                    onOk={this.handleAddressCancel}
                    onCancel={this.handleAddressCancel}
                    width={700}
                >
                    <div className='pdb50'>
                        <p >收货人：{trade.name}</p>
                        <p className='mgt15'>手机号：{trade.phone}</p>
                        <p className='mgt15'>收货地址：{trade.user_address}</p>
                    </div>
                </Modal>

                {this.state.is_hx ? <HxStore success={this.fetchData} use_code={trade.use_code} close={this.handleHxCancel} join_id={this.state.join_id} /> : null}
                {this.state.isLoading?<Loading />:null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(CollectLikesWinners))