import React from "react";
import { Table, Pagination, Form } from 'antd'
import { GetFxUserData } from '@/api/fx'
const FormItem = Form.Item

class SubFx extends React.Component {
    state = {
        columns:[
            {
                title:'分销员信息',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.name }</p>
                            <p className={`lh25`}>{ rowData.mobile }</p>
                        </div>
                    )
                }
            },
            {
                title:'成为分销员时间',
                dataIndex:'agent_time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.agent_time }</p>
                        </div>
                    )
                }
            },
            {
                title:'累计收益',
                dataIndex:'total_commission',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.total_commission }</p>
                        </div>
                    )
                }
            },
            {
                title:'绑定方式',
                dataIndex:'bind_source_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.bind_source_name }</p>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10

    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {};
        sendData.user_id = this.props.user_id || '';
        sendData.type = 2;
        sendData.page = this.state.page
        this.setState({
            tableLoading:true
        })
        GetFxUserData(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list.data,
                        per_page = res.data.list.per_page,
                        total = res.data.list.total;
                    console.log('tableData',tableData)
                    this.setState({
                        tableData,per_page,total
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        return (
            <div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']} 
                                    showQuickJumper
                                    current={this.state.page}
                                    total={this.state.total}
                                    pageSize={this.state.per_page}
                                    onChange={this.handleCurrentChange}
                                />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(SubFx)
