import React, { Component } from 'react'
import { Table, Pagination, Divider, Button, Modal, Form, Input, Row, Col, Radio, Select } from 'antd'
import { gradeMoveStudent, gradeAddStudent, gradeStudentApi, gradeApi ,searchGradeStudent} from '@/api/educational'
import { studentLists, studentAdd, studentDele } from '@/api/student'
import GradeStudentAdd from './gradeStudentAdd'
import message from '@/utils/message'
import CX from '@/utils/CX'
const FormItem = Form.Item;

let timeout;
let currentValue;
function fetch(value,id, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    function fake() {
        searchGradeStudent({ student_name: value,grade_id: id}).then(res => {
            if (res.code === 1) {
                let { data } = res.data.list
                callback(data)
            }
        })
    }

    timeout = setTimeout(fake, 800);
}

class GradeStudent extends Component {

    state = {
        columns: [
            {
                title: '学员',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.real_name || "-"}</p>
                }
            },
            {
                title: '学员手机号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.parent_mobile || "-"}</p>
                }
            },
            {
                title: '已用课时',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return <p>{rowData.use_course_num}</p>
                }
            },
            {
                title: '剩余课时',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.course_num}</p>
                }
            },
            {
                title: '课程有效期',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return <p>{"-"}</p>
                }
            },

            {
                title: '操作',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleMove(rowData.student_id) }}>调班</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData.student_id) }}>移除</Button>

                        </div>
                    )
                }
            },

        ],
        tableData: [],
        gradeList: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        is_show: false,
        current_student_id: "",
        confirmLoading: false,
        is_add_student: false,
        is_batch_move: false,
        move_student_data: []
    }

    componentDidMount() {
        this.fetchData()
        this.fetchGrade()
    }

    fetchGrade = () => {
        let sendData = {}
        sendData.page = 1
        sendData.per_page = 100

        gradeApi(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data

                    this.setState({
                        gradeList: lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.grade_id = this.props.grade_id
        this.setState({
            tableLoading: true
        })
        gradeStudentApi(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    handleMove = id => {
        this.setState({
            is_show: true,
            current_student_id: id,
            is_batch_move: false
        })
    }

    batchMove = () => {
        this.setState({
            is_show: true,
            is_batch_move: true
        })
    }

    handleDele = id => {
        let sendData = {}
        sendData.grade_id = this.props.grade_id
        sendData.student_ids = [id]
        sendData.type = 2
        sendData.to_grade_id = ""

        Modal.confirm({
            title: `确定移除该名学员，并删除其在该班级内的所有未签到课节？`,
            okText: "我在想想",
            cancelText: "确认",
            onOk: () => {
            },
            onCancel: () => {
                gradeMoveStudent(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                    this.props.refreshDetail()
                                })
                        }
                    })
            }
        })
    }


    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let sendData = values

                if (sendData.student_ids) {
                    if (!sendData.student_ids.length) {
                        this.setState({
                            confirmLoading: false,
                            is_show: false
                        })
                        return
                    }
                } else {
                    sendData.student_ids = [this.state.current_student_id]
                }
                // sendData.student_id = this.state.current_student_id
                this.setState({
                    confirmLoading: true
                })
                gradeMoveStudent(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                    this.props.refreshDetail()
                                })
                        }
                    }).finally(() => {
                        this.setState({
                            confirmLoading: false,
                            is_show: false
                        })
                    })
            }
        })
    }
    handleCancel = () => {
        this.setState({
            is_show: false
        })
    }

    handleAdd = () => {
        this.setState({
            is_add_student: true
        })
    }

    closeStudentList = () => {
        this.setState({
            is_add_student: false
        })
    }
    handleAddStudent = arr => {
        if (arr.length === 0) {
            return this.closeStudentList()
        }

        let sendData = {}
        sendData.grade_id = this.props.grade_id
        sendData.student_ids = arr
        gradeAddStudent(sendData).then(res => {
            if (res.code == 1) {
                message.success("学员添加成功")
                this.fetchData()
                this.props.refreshDetail()
            }
            this.setState({
                is_add_student: false
            })
        })
    }

    handleSearch = (value) => {
      
        if (value) {
            fetch(value,this.props.grade_id, data => this.setState({ move_student_data: data }))
        } else {
            this.setState({
                move_student_data: []
            })
        }
    }



    render() {
        let data = {}
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 17
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return (
            <div className='pdb20'>


                {
                    this.state.tableData.length >= this.props.grade_num ? null :
                        <div className={'mgt10 mgb30'} >
                            <Button type={'primary'} icon={'plus'} onClick={this.handleAdd}>添加学员</Button>
                            <Button type='default' className='mgl20' onClick={this.batchMove}>批量调班</Button>
                        </div>
                }

                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.is_show ?
                        <Modal
                            title="调班"
                            visible={this.state.is_show}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            confirmLoading={this.state.confirmLoading}
                        >
                            <Form {...formItemLayout} className={'model-form'}>
                                <Row gutter={24}>
                                    <Col span={20}>

                                        {
                                            this.state.is_batch_move ?
                                                <FormItem label="学员选择">
                                                    {getFieldDecorator('student_ids', {
                                                        initialValue: undefined
                                                    })(
                                                        <Select
                                                            mode="multiple"

                                                            placeholder={'请输入关键字搜索'}
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={false}
                                                            onSearch={this.handleSearch}
                                                            notFoundContent={null}
                                                        >
                                                            {
                                                                this.state.move_student_data.map(val => {
                                                                    return <Select.Option value={val.id} key={val.id}>{val.real_name}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </FormItem> : null
                                        }
                                        <FormItem label="调整类型">
                                            {getFieldDecorator('type', {
                                                initialValue: 1
                                            })(
                                                <Radio.Group >
                                                    <Radio value={1}>调整当前班级</Radio>
                                                    <Radio value={2}>从班级移除</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>

                                        <FormItem label="调出班级">
                                            {getFieldDecorator('grade_id', {
                                                initialValue: Number(this.props.grade_id),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '调出班级'
                                                    }
                                                ]
                                            })(
                                                <Select disabled={true}>
                                                    {
                                                        this.state.gradeList.map(v => (
                                                            <Select.Option value={v.id} key={v.id}>{v.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            )}
                                        </FormItem>

                                        {
                                            getFieldValue("type") === 1 ?
                                                <FormItem label="调入班级">
                                                    {getFieldDecorator('to_grade_id', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '调入班级'
                                                            }
                                                        ]
                                                    })(
                                                        <Select placeholder="请选择将要调入的班级" >
                                                            {
                                                                this.state.gradeList.map(v => (
                                                                    <Select.Option value={v.id} key={v.id}>{v.name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    )}
                                                </FormItem> : null
                                        }


                                    </Col>
                                    <Col span={4}>
                                    </Col>
                                </Row>
                            </Form>

                        </Modal> : null
                }
                {this.state.is_add_student ? <GradeStudentAdd close={this.closeStudentList} success={this.handleAddStudent} /> : null}
            </div>
        )
    }
}

export default Form.create({})(GradeStudent)

