import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select, Button, Radio, DatePicker, Table, Pagination } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import '@/assets/css/dashboard/index.scss'
import '@/assets/css/finance/index.scss'
import '@/assets/css/fx/fx.scss'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class FxSort extends React.Component{
    state = {
        formData:'',
        columns:[
            {
                title:'排名',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>13112341234</p>
                        </div>
                    )
                }
            },
            {
                title:'分销员',
                dataIndex:'commission_type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>商家调整</p>
                        </div>
                    )
                }
            },
            {
                title:'总收益金额（元）',
                dataIndex:'commission',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>-&yen;20.00</p>
                        </div>
                    )
                }
            },
            {
                title:'订单数',
                dataIndex:'withdraw',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;100.00</p>
                        </div>
                    )
                }
            },
            {
                title:'邀请分销员数',
                dataIndex:'remark',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>扣除多发放金额</p>
                        </div>
                    )
                }
            },
            {
                title:'绑定客户数',
                dataIndex:'create_at',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>2022-02-18 12:27:00</p>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {

    }
    handleExport = () => {}
    render() {
        const { getFieldDecorator } = this.props.form
        let data = this.state.formData || {}
        return(
            <div className={``}>
                <Form className={''}>
                    <div className={`content-head mgb15`}>
                        <div className={`clearfix`}>
                            <FormItem label={<span className={`ft-bold`}>分销员中心排名设置（小程序端）</span>} className={`fl mgl20`} colon={false}>
                                {
                                    getFieldDecorator('sort_type',{
                                        initialValue:data.sort_type || 1
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>按总收益金额排名</Radio>
                                            <Radio value={2} className={`mgl20`}>按下级分销员和客户数之和排名</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}></div>
                    </div>
                    <div className={`search-form`}>
                        <div className={`clearfix`}>
                            <FormItem label={`时间筛选`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:150}} placeholder={'请选择'} className={`mgr10`}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>月汇总</Select.Option>
                                            <Select.Option value={2}>日汇总</Select.Option>
                                            <Select.Option value={3}>自定义时间</Select.Option>
                                        </Select>
                                    )
                                }
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.handleExport()}>导出</Button>
                            </FormItem>
                        </div>
                    </div>
                    <div className={'content-body '}>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        ></Table>
                    </div>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}
export default Form.create({})(withRouter(FxSort))
