import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Modal, Tag, InputNumber, Drawer, Icon, message } from 'antd'
import moment from 'moment'
import LikesDetail from './components/likesDetail'
import Loading from '@/components/Loading'
import { jizanActive, jizanVirtualNum, exportActive ,exportRecord} from "@/api/collect-likes.js"
import CX from '@/utils/CX'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class CollectLikesInitiator extends Component {

    state = {
        columns: [
            {
                title: '客户信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.name || ''}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '已点赞/需点赞',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.likes_num}/{this.state.detail.jizan_num} </p>
                    )
                }
            },
            {
                title: '虚拟点赞数',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.virtual_num} </p>
                    )
                }
            },
            {
                title: '参与时间',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at} </p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        
                        <Tag color={rowData.status == 1 ? "" : "green"}> {rowData.status == 1 ? "已完成" : "进行中"}</Tag>
                    )
                }
            },

            {
                title: '操作',
                dataIndex: '6',
                width: "340px",
                render: (text, rowData, index) => {

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDetail(rowData) }}>查看点赞明细</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleAddvirtualNum(rowData) }}>增加虚拟点赞</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleExportRecord(rowData) }}>导出点赞明细</Button>
                        </div>
                    )
                }
            }
        ],
        searchForm: {
            mobile: "",
            status: 0,
            start_time: "",
            end_time: ""
        },
        detail: "",
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
        is_detail: false,
        promoter_id: 0,
        virtual_num: 0,
        visible: false,
        confirmLoading: false,
        join_id: "",
        isLoading:false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.id = this.props.match.params.id
        this.setState({
            tableLoading: true
        })
        jizanActive(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data, detail: res.data.detail
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                sendData = values;
                sendData.id = this.props.match.params.id

                this.setState({
                    isLoading:true
                })
                exportActive(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '发起人数据.csv';
                        CX.exportExcel(blob, fileName)
                    }).finally(()=>{
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    handleExportRecord= row =>{
        this.setState({
            isLoading:true
        })
        exportRecord({join_id:row.id})
            .then(res => {
                let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                    fileName = '点赞明细.csv';
                CX.exportExcel(blob, fileName)
            }).finally(()=>{
                this.setState({
                    isLoading:false
                })
            })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        searchForm.status = 0

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleDetail = row => {
        this.setState({
            join_id: row.id,
            is_detail: true
        })
    }
    handleCloseDetail = () => {
        this.setState({
            is_detail: false
        })
    }

    handleAddvirtualNum = row => {
        this.setState({
            join_id: row.id,
            visible: true
        })
    }
    handleChange = val => {
        this.setState({
            virtual_num: val
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    handleOk = () => {
        const { virtual_num, join_id } = this.state

        this.setState({
            confirmLoading: true
        })
        jizanVirtualNum({ virtual_num, join_id }).then(res => {
            if (res.code == 1) {
                this.fetchData()
                message.success(res.msg)
            }
        }).finally(() => {
            this.setState({
                confirmLoading: false,
                visible: false,
                virtual_num: 0
            })
        })
    }



    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户手机号'} className={'fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'状态'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>进行中</Select.Option>
                                            <Select.Option value={2}>已完成</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem  label={<span>&emsp;参与时间</span>} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem  className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => this.handleExport()}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>


                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>


                <Modal
                    title="增加虚拟票数"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.confirmLoading}
                    width={600}
                >
                    <div className='pdb50'>
                        <span>虚拟票数：</span>
                        <InputNumber
                            style={{ width: 300 }}
                            min={0}
                            precision={0}
                            onChange={this.handleChange}
                            value={this.state.virtual_num}
                        />
                    </div>
                </Modal>
                
                {this.state.isLoading?<Loading />:null}
                {this.state.is_detail ? <LikesDetail join_id={this.state.join_id} close={this.handleCloseDetail} /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(CollectLikesInitiator))