import React, { Component } from 'react'
import { Form, Input, Button, Table, Pagination,  Icon } from 'antd'

export default class StudentIntroduce extends Component {
    state={
        columns: [

            {
                title: '导入时间',
                dataIndex: '1',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <p></p>
                    )
                }
            },
            {
                title: '导入数',
                dataIndex: '2',
                width: '130px',
                render: (text, rowData, index) => {
                    return (
                        <p></p>
                    )
                }
            },
            {
                title: '成功/失败',
                dataIndex: '3',
                width: '100px',
                render: (text, rowData, index) => {
                    return (
                        <p></p>
                    )
                }
            },
            {
                title: '导入状态',
                dataIndex: '4',
                width: '130px',
                render: (text, rowData, index) => {
                    return (
                        <p></p>
                    )
                }
            },
            {
                title: '操作人',
                dataIndex: '7',
                width: '150px',
                render: (text, rowData, index) => {
                    return (
                        <p></p>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        per_page:10,
        total:0

    }

    fetchData=()=>{

    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    render() {
        return (
            <div className='shadow-radius'>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} >批量学员导入</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}
