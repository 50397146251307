import React, { Component } from 'react'
import { Form, Input, Button, Select, Pagination,Table,Divider} from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { teacherApi} from '@/api/educational'
import ShareModal from '@/components/shareModal/shareModal'
import message from '@/utils/message'
const FormItem = Form.Item;

class TeacherList extends Component {

    state = {
        storeLists:[],
        columns: [
            {
                title: '老师姓名',
                dataIndex: '1',
                
                render: (text, rowData, index) => {
                    return (
                        <div >{rowData.name}</div>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '2',
            
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.phone}</div>
                    )
                }
            },
            {
                title: '职位',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {rowData.position && rowData.position.name ||"-"}
                        </div>
                    )
                }
            },
            {
                title: '角色',
                dataIndex: '4',
                render: (text, rowData, index) => {
                  
                    return (
                        <div>
                           {rowData.role_name}
                        </div>
                    )
                }
            },
            {
                title: '所属校区',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.store.name}</div>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: '6',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.sort||"-"}</div>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.status==1?"在职":"离职"}</div>
                    )
                }
            },
          
            {
                title: '操作',
                dataIndex: '8',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleEdit(rowData.id)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleShare(rowData)}}>推广</Button>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        searchForm:{
            name:"",
            store_id:0,
            status:0
        },
        tableLoading:false,
        page:1,
        per_page:10,
        total:0,
        poster:"",
        path:""

    }
    componentDidMount() {
       this.fetchStore()
       this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData=this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        teacherApi(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                  
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }
    
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name:"",
            status:0,
            store_id:0
        }
     
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleEdit=id=>{
        this.props.history.push({
            pathname:`/educational/teacher-edit/`+id
        })
        
    }

    handleShare = row => {
        this.setState({
            poster:'',
            path:`/pages/teacher/detail?teacher_id=${row.id}`
        })
        this.ShareModal.show()
    }


    render() {
      
        const { getFieldDecorator } = this.props.form
      
        return (
          <div>
              <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'老师姓名'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入老师姓名' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'所属校区'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>在职</Select.Option>
                                            <Select.Option value={2}>离职</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>
                        
                        <div className={`clearfix`}>

                            <FormItem className={'fl mgl70'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                  
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
          </div>
        )
    }
}

export default Form.create({})(withRouter(TeacherList))