import React, { Component } from 'react'
import { Table, Pagination} from 'antd'
import { studentGrade} from '@/api/student'

class GradeTab extends Component {
    state = {
        columns: [
            {
                title:<span className='mgl16'>班级名称</span> ,
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p className='mgl16'>{rowData.name}</p>
                }
            },
            {
                title: '老师/助教',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.teacher_name}{rowData.assist_teacher_name?"/":""}{rowData.assist_teacher_name||""}</p>
                }
            },
            {
                title: '开班时间/结班时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (<div>
                        <p>开班时间：{rowData.start_date}</p>
                        <p>结班时间：{rowData.end_date}</p>
                    </div>)
                }
            },
            {
                title: '上课地点',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{rowData.store_name}</p>
                }
            },
            {
                title: '已上/排课总数',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return <p>{rowData.sign_num}/{rowData.table_num}</p>
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page:1,
        per_page:10,
        total:0,
    }

    componentDidMount(){
        this.props.refGradeTab(this)
        this.fetchData()
    }

    fetchData=()=>{
        let sendData={}
        sendData.student_id=this.props.student_id
        sendData.page=this.state.page
        sendData.per_page=this.state.per_page
        this.setState({
            tableLoading:true
        })
        studentGrade(sendData).then(res=>{
           
            if(res.code==1){
                let grade_list=res.data.grade_list
                let total=grade_list.total,
                    per_page=grade_list.per_page,
                    tableData=grade_list.data
                this.setState({
                    total,per_page,tableData
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading:false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }


    render() {
        return (
            <div className='pdb20'>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default GradeTab
