import React from "react";
import { Link } from 'react-router-dom'
import { Table, Pagination, Form, Select, Button, DatePicker } from 'antd'
import { GetFxUserData } from '@/api/fx'
import moment from 'moment'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class FxCommissionSettled extends React.Component {
    state = {
        searchForm:{
            status:'',
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'商户单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.order_sn }</p>
                        </div>
                    )
                }
            },
            {
                title:'实付金额',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.pay_ment }</p>
                        </div>
                    )
                }
            },
            {
                title:'客户信息',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.name }</p>
                            <p className={`lh25`}>{ rowData.phone }</p>
                        </div>
                    )
                }
            },
            {
                title:'订单类型',
                dataIndex:'trade_type_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.trade_type_name }</p>
                        </div>
                    )
                }
            },
            {
                title:'收益明细',
                dataIndex:'commission',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>预计收益:&yen;{ rowData.total_commission }</p>
                            <p className={`lh25`}>手续费：&yen;{ rowData.charge_commission }</p>
                            <p className={`lh25`}>实际收益：&yen;{ rowData.pre_commission }</p>
                        </div>
                    )
                }
            },
            {
                title:'下单门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.store?.name }</p>
                        </div>
                    )
                }
            },
            {
                title:'结算状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.is_commission == 1 ? '已结算' : '待结算' }</p>
                        </div>
                    )
                }
            },
            {
                title:'下单时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.created_at }</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Link to={`/order/detail/${rowData.order_sn}`} target={`_blank`} className={`fi-link lh25`}>查看详情</Link>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10

    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.user_id = this.props.user_id || '';
        sendData.type = 3;
        sendData.page = this.state.page
        this.setState({
            tableLoading:true
        })
        GetFxUserData(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list.data,
                        per_page = res.data.list.per_page,
                        total = res.data.list.total;
                    console.log('tableData',tableData)
                    this.setState({
                        tableData,per_page,total
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    search = () =>  {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                <Form className={`search-form`}>
                    <div className={`clearfix`}>
                        <FormItem label={`结算状态`} className={`fl`}>
                            {
                                getFieldDecorator('status')(
                                    <Select style={{width:200}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        <Select.Option value={1}>待结算</Select.Option>
                                        <Select.Option value={2}>已结算</Select.Option>
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem label={`下单时间`} className={`fl mgl15`}>
                            {
                                getFieldDecorator('time')(
                                    <RangePicker></RangePicker>
                                )
                            }
                        </FormItem>
                        <FormItem label={` `} colon={false} className={'fl mgl5'}>
                            <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                        </FormItem>
                    </div>
                </Form>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']} 
                                    showQuickJumper
                                    current={this.state.page}
                                    total={this.state.total}
                                    pageSize={this.state.per_page}
                                    onChange={this.handleCurrentChange}
                                />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(FxCommissionSettled)
