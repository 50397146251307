import React from 'react'
import { Form, Input, Button, Table, Pagination, Divider, Select, Switch, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
import CourtTypeManageLists from './components/CourtTypeLists'
import AddCourt from './components/AddCourt'
import { storeCourtManage, addStoreCourt, storeCourtDetail, saveStoreCourt, delStoreCourt, getCourtTypes } from '@/api/court'
const FormItem = Form.Item

class CourtLists extends React.Component{
    state = {
        searchForm:{
            name:'',
            space_type_id:''
        },
        columns:[
            {
                title:'场地号',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'规模',
                dataIndex:'space_size',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            { rowData.size_type == 1 ? <p>全场</p> : null }
                            { rowData.size_type == 2 ? <p>半场</p> : null }
                            { rowData.size_type == 0 ? <p>{rowData.space_size || '--'}</p> : null }
                        </div>
                    )
                }
            },
            {
                title:'场地类型',
                dataIndex:'space_type',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.space_type?.name ||'--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'隐藏场地',
                dataIndex:'is_hide',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{ rowData.is_hide == 1 ? '是' : '否' }</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleEdit(rowData) } }>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleDelete(rowData) } }>删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        courtTypes:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:'',
        poster:'',
        path:''
    }
    componentDidMount() {
        this.fetchData()
        this.fetchCourtTypes()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.store_id = this.props.match.params.id || ``;
        this.setState({
            tableLoading:true
        })
        storeCourtManage(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.space_list.data,
                        total = res.data.space_list.total,
                        per_page = res.data.space_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchCourtTypes = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        sendData.store_id = this.props.match.params.id || ``;
        getCourtTypes(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.space_type_list.data;
                    this.setState({
                        courtTypes:lists,
                    })

                }
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleManage = () => {
        this.CourtTypeManageLists.show(res => {
            this.fetchCourtTypes()

        })
    }
    handleAdd = () => {
        this.setState({
            currentRow:''
        },() => {
            this.AddCourt.show(() => {
                this.fetchData();
            })
        })

    }
    handleEdit = (rowData) => {
        if(rowData.size_type  == 0){
            rowData.space_size_name = rowData.space_size
        }
        this.setState({
            currentRow:rowData
        },() => {
            this.AddCourt.show(() => {
                this.fetchData();
            })
        })
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id;
        sendData.store_id = this.props.match.params.id || ``;
        Modal.confirm({
            title:'是否删除该场地',
            onOk:() => {
                this.setState({
                    tableLoading:true
                })
                delStoreCourt(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })

            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/scan/item?desk_id=${row.id}`
        })
        this.ShareModal.show()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <CourtTypeManageLists onRef={ e => { this.CourtTypeManageLists = e } }></CourtTypeManageLists>
                <AddCourt onRef={ e => { this.AddCourt = e } } courtTypes={this.state.courtTypes} data={this.state.currentRow}></AddCourt>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'场地号'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索场地号'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'场地类型'} className={'fl mgl20'}>
                            {
                                getFieldDecorator('space_type_id',{
                                    initialValue: 0
                                })(
                                    <Select style={{width:200}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        {
                                            this.state.courtTypes.map((item,index) => (
                                                <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={''}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加场地号</Button>
                    <Button type={'default'} className={`mgl10`} onClick={this.handleManage} >管理场地类型</Button>

                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourtLists))
