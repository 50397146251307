import React from 'react'
import { Modal, Table, Input, Button, Pagination } from 'antd'
import { searchPickUp, pickUpCode, verifyTimesCard } from '@/api/order'
import message from '@/utils/message'
import TimesCardPickUp from './times-card-pickup'
import './pickup.scss'

class PickUpSearch extends React.Component{
    state = {
        use_code:'',
        title:'在线核销',
        visible:false,
        modalLoading:false,
        columns:[
            {
                title:'订单编号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.order_sn || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title:'客户/收货人',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.user_address ? rowData.user_address.name : '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'订单内容',
                dataIndex:'content',
                width:200,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.goods_info || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'订单金额',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.pay_ment }</p>
                        </div>
                    )
                }
            },
            {
                title:'类型',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.trade_type_name }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销码',
                dataIndex:'use_code',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.use_code }</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opt',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.trade_status == 2 && rowData.trade_type != 11
                                    ?
                                    <p className={`lh25`}>已核销</p>
                                    :
                                    <Button type={`link`} size={`small`} onClick={ () => { this.handleUseCode(rowData) } }>核销</Button>
                            }
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
        callback:null,
        currentRow:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            callback:callback || null,
            visible:true
        })

    }
    close = () => {
        this.setState({
            visible:false,
            use_code:'',
            tableData:[]
        })
    }
    fetchData = () => {
        let sendData = {};
        sendData.use_code = this.state.use_code;
        sendData.page = this.state.page;
        sendData.per_page = 10
        this.setState({
            tableLoading:true
        })
        searchPickUp(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData:lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleOk = () => {
        let callback = this.state.callback
        if(typeof callback == 'function'){
            callback({})
        }
        this.close()
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleInput = e => {
        e.persist()
        let val = e.target.value;
        this.setState({
            use_code:val
        })
    }
    handleUseCode = (rowData) => {
    
        let sendData = {};
        if(rowData.trade_type == 11){
            this.setState({
                currentRow:rowData
            },() => {
                this.TimesCardPickUp.show(res => {
                    console.log(res)
                    sendData.user_time_card_id = rowData?.time_card_info?.id || '';
                    sendData.goods_list = res;
                    this.setState({
                        tableLoading:true
                    })
                    verifyTimesCard(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('核销成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                tableLoading:false
                            })
                        })
                })
            })

        }else {
            sendData.use_code = rowData.use_code || ``;
            sendData.order_sn = rowData.order_sn || ``;
            Modal.confirm({
                title:'是否对这笔订单进行核销？',
                okType:'primary',
                onOk:() => {
                    this.setState({
                        tableLoading:true
                    })
                    pickUpCode(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('核销成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                tableLoading:false
                            })
                        })

                }
            })
        }

    }

    render() {
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                    centered={true}
                    footer={false}
                >
                    <TimesCardPickUp
                        onRef={ e => { this.TimesCardPickUp = e } }
                        user_time_card_id={ this.state.currentRow?.time_card_info?.id || '' }
                    ></TimesCardPickUp>
                    <div className={`txtCenter`}>
                        <div className={`searchBox`}>
                            <Input  value={ this.state.use_code } type={`text`} className={`pickup-search`} placeholder={`请输入核销码或手机号`} onChange={ this.handleInput } />
                            <Button type={`primary`} icon={'search'} className={`pick-up-btn`} onClick={ this.fetchData }>查询</Button>
                        </div>
                        {/*<div className={`pick-img-cont`}>*/}
                            {/*<img src="https://img.cxkoo.com/chengxuan/1/2021-11-03/29a4a4de691693eb9190b8e710755e8c.png"></img>*/}
                            {/*<div className={`img-txt`}>请顾客出示会员码、服务核销码、或商品自提码，可使用扫码枪核销</div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={'content-body mgt20'}>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        ></Table>
                    </div>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>

                </Modal>
            </div>

        )
    }
}

export default PickUpSearch
