import React from 'react'
import { Form, Input, Button, Table, Pagination, Modal, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import AddCategory from './components/addCategory'
import message from '@/utils/message'
import { itemCates, addCate, cateDetail, editCate, deleteCate } from '@/api/item'
const FormItem = Form.Item;

class ItemCategory extends React.Component{
    state = {
        columns:[
            {
                title:'分类',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <span>{rowData.name || '--'}</span>
                    )
                }
            },
            {
                title:'图片',
                dataIndex:'cover_img',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <img src={rowData.cover_img} width={60} alt=""/>
                        </div>
                    )
                }
            },
            {
                title:'排序号',
                dataIndex:'sort',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.sort || '--'}</p>
                    )
                }
            },
            {
                title:'关联商品数',
                dataIndex:'goods_num',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.goods_num || '--'}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            {
                                rowData.pid == 0
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleChild(rowData)}}>新增子分类</Button>
                                    :
                                    null
                            }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:''
        },
        tableData:[],
        tableLoading:false,
        cateVisible:false,
        cateModalLoading:false,
        currentCate:{},
        pid:0,
        page:1,
        total:0,
        per_page:10

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        itemCates(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    lists.forEach(item => {
                        if(item.children && item.children.length < 1){
                            delete item.children
                        }
                    })
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.setState({
            pid:0,
            currentCate:null,
            cateVisible:true
        })
    }
    handleAddCate = () => {
        this.formRef.getData(res => {
            if(res){
                if(this.state.cateModalLoading){ return; }
                this.setState({
                    cateModalLoading:true
                })
                res.pid = this.state.pid;
                if(this.state.currentCate){
                    res.id = this.state.currentCate.id || ``
                    editCate(res)
                        .then(resp => {
                            if(resp.code == 1){
                                message.success(resp.msg)
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                cateModalLoading:false,
                                cateVisible:false
                            })
                        })
                }else {
                    addCate(res)
                        .then(resp => {
                            if(resp.code == 1){
                                message.success(resp.msg)
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                cateModalLoading:false,
                                cateVisible:false
                            })
                        })

                }


            }
        })
    }
    handleCateCancel = () => {
        this.setState({
            cateVisible:false
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        let pid = rowData.pid || 0;
        this.setState({
            currentCate:rowData,
            pid:pid,
            cateVisible:true
        })
    }
    handleChild = rowData => {
        let pid = rowData.id,
            currentCate = null;
        this.setState({
            pid:pid,
            currentCate:currentCate,
            cateVisible:true
        })
    }
    handleDelete = row => {
        let sendData = {}
        sendData.id = row.id || ``;
        sendData.pid = row.pid ||``;
        Modal.confirm({
            title:'确定要删除这条数据吗?',
            onOk:() => {
                this.setState({
                    tableLoading:true
                })
                deleteCate(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })
            }
        })
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'分类名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索分类名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增分类</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.cateVisible
                        ?
                        <Modal
                            title={'添加分类'}
                            visible={this.state.cateVisible}
                            onOk={this.handleAddCate}
                            onCancel={this.handleCateCancel}
                            maskClosable={false}
                            confirmLoading={this.state.cateModalLoading}
                        >
                            <AddCategory
                                data={this.state.currentCate}
                                visible={this.state.cateVisible}
                                wrappedComponentRef={form => {this.formRef = form} }
                            ></AddCategory>
                        </Modal>
                        :
                        null
                }

            </div>
        )
    }
}

export default Form.create({})(withRouter(ItemCategory))
