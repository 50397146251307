import React from 'react'
import { Form, Button, Tag, Table, Pagination, Divider, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { suitStores } from '@/api/member-card'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CouponFitStore from '@/views/coupon/components/CouponFitStore'
const FormItem = Form.Item;

class CardRights extends React.Component{
    state = {
        columns:[
            {
                title:'卡项内容',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || ''}</p>
                    )
                }
            },
            {
                title:'购卡次数',
                dataIndex:'count',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{ rowData.count == -1 ? '无限次':(rowData.count+'次') }</p>
                        </div>
                    )
                }
            },
            {
                title:'已用次数',
                dataIndex:'use_count',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            { rowData.use_count }次
                        </div>
                    )
                }
            },
            {
                title:'剩余次数',
                dataIndex:'last_count',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p> { rowData.last_count ==-1?'无限次':(rowData.last_count+'次') }</p>
                        </div>
                    )
                }
            }
        ],
        suitStores:[],
        tableLoading:false,
        selectRows:[]

    }

    componentDidMount() {
        this.props.onRef(this)
        //this.fetchData()
    }
    fetchData = () => {}
    handleCurrentChange = val => {

    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/order/detail/${rowData.order_sn || ''}`
        })
    }





    render() {
        let tableData = this.props.tableData || [],
            per_page = this.props.per_page || 10,
            page = this.props.page || 1,
            total = this.props.total || 0;
        const { getFieldDecorator } = this.props.form
        return(
            <div>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                { this.state.isLoading ? <Loading /> : null }
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}></div>
                    <div className={'fr'}>
                        {
                            tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={page} total={total} pageSize={per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Form.create({})(withRouter(CardRights))
