import React from 'react'
import { Form, Input, Button, Select, Table, Pagination,InputNumber, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { CourseCategoryApi, CourseListsApi, CourseSaveApi, setStatusApi ,setCategoryApi,deleteApi,copyApi} from '@/api/course'
import message from '@/utils/message'
import SetCourseCategory from './components/setCourseCategory'
import ShareModal from '@/components/shareModal/shareModal'
import { storeLists } from '@/api/store'
import Loading from '@/components/Loading'

const { TreeNode } = TreeSelect;

const FormItem = Form.Item;

class CourseOffline extends React.Component {

    state = {
        selectRows: [],
        searchForm: {
            status: 0,
            name: '',
            category_id: '',
            charge_type: '',
            store_id: '',
            order_field:'',
            order_asc:''
        },
        columns: [
            {
                title: '课程名称',
                dataIndex: 'name',
                width: '260px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.pic_urls && rowData.pic_urls[0]} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <p>{rowData.name || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '课程价格',
                dataIndex: 'price',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.price_edit
                                    ?
                                    <Input
                                        type={`number`}
                                        style={{ width: '80px' }}
                                        value={rowData.price}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.handleField(e, rowData, index) }}
                                        data-name={'price'}
                                        min={'0'}
                                        step={'0.01'}
                                    />
                                    :
                                    <span>{rowData.price ? `￥${rowData.price}` : `--`}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`price`} />
                        </div>
                    )
                }
            },
            {
                title: '课程类目',
                dataIndex: 'course_category',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.course_category ? rowData.course_category.name : `--`}</span>
                        </div>
                    )
                }
            },
            {
                title: '收费方式',
                dataIndex: 'charge_type',
                render: (text, rowData, index) => {
                    let charge_type
                    if (rowData.charge_type == 1) {
                        charge_type = "按课时"
                    } else if (rowData.charge_type == 2) {
                        charge_type = "按时段"
                    } else {
                        charge_type = "按期"
                    }
                    return (
                        <div>
                            {
                                charge_type
                            }
                        </div>
                    )
                }
            },
            {
                title: '实际销量/总销量',
                dataIndex: 'basic_sales',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.real_sales || 0}/{rowData.sales||0}</p>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: 'sort',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sort_edit
                                    ?
                                    <Input
                                        style={{ width: '80px' }}
                                        value={rowData.sort}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.handleField(e, rowData, index) }}
                                        data-name={'sort'}
                                    />
                                    :
                                    <span>{rowData.sort}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />

                        </div>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (text, rowData, index) => {
                    let status_color, status_text
                    if (rowData.status == 1) {
                        status_text = "出售中"
                        status_color = "green"
                    } else if (rowData.status == 2) {
                        status_text = "已售罄"
                        status_color = "red"
                    } else {
                        status_text = "已下架"
                        status_color = ""
                    }
                    return (
                        <Tag color={status_color}>{status_text}</Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '15%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 3
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => { this.setStatus([rowData.id], 1) }}>上架</Button>
                                    :
                                    <Button type={"link"} size={"small"} onClick={() => { this.setStatus([rowData.id], 0) }}>下架</Button>
                            }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleShare(rowData) }}>推广</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.copyCourse(rowData.id) }}>复制</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.delteCourse([rowData.id]) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        storeLists: [],
        courseLists: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        poster: '',
        path: ''
    }

    componentDidMount() {
        this.fetchStore()
        this.fetchCourse()
        this.fetchData()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
    }

    fetchCourse = () => {

        CourseCategoryApi({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                data.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.disabled = item.children && item.children.length > 0
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })

                this.setState({
                    courseLists: data
                })
            }
        })
    }

    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        CourseListsApi(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                    lists.forEach(item => {
                        item.price_edit = 0;
                        item.sort_edit = 0;
                    })

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleAdd = () => {
        this.props.history.push({
            pathname: `/course/offline-add`
        })
    }

    handleEdit = row => {
        this.props.history.push({
            pathname:`/course/offline-add/${row.id}`
        })

    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        searchForm.status = 0;
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchForm = this.state.searchForm;
        if (order) {
            searchForm.order_field = field;
            searchForm.order_asc = order == 'ascend' ? 'asc' : 'desc';
        } else {
            searchForm.order_field = '';
            searchForm.order_asc = '';
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'price':
                lists[index]['price_edit'] = 1
                break;
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
        }
        this.setState({
            tableData: lists
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = { ...rowData }
        sendData[name] = val;

        sendData["price"]=Math.abs(sendData["price"])


        CourseSaveApi(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }

    setStatus = (ids, status,is_batch) => {

        Modal.confirm({
            title: `确定要${status==1?'上':'下'}架${is_batch?'这些':'该'}课程吗？`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                setStatusApi({ ids, status })
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                            selectedRowKeys:[],
                            selectRows:[]
                        })
                    })
            }
        })
    }

    delteCourse=(ids,is_batch)=>{
        Modal.confirm({
            title:`确定要删除${is_batch?'这些':'该'}课程吗？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                deleteApi({ids})
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false,
                            selectedRowKeys:[],
                            selectRows:[]
                        })
                    })
            }
        })
    }

    copyCourse=id=>{
        Modal.confirm({
            title:`是否要复制该课程？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                copyApi({id})
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }

    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/course/detail?course_id=${row.id}`
        })
        this.ShareModal.show()
    }

    patchOn = () => {
        let ids,
            selectRows = this.state.selectRows;
        if (!selectRows.length) {
            message.error('请选择要上架的课程')
            return;
        }
        ids = selectRows.map(item => {
            return item.id
        })
        this.setStatus(ids,1,true)
    }

    patchOff= () => {
        let ids,
            selectRows = this.state.selectRows;
        if (!selectRows.length) {
            message.error('请选择要下架的课程')
            return;
        }
        ids = selectRows.map(item => {
            return item.id
        })
        this.setStatus(ids,0,true)
    }

    patchCates = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择课程')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        this.ItemSetCategory.show(res => {
            if(res){
                let category_id = res.category_id;
                sendData.category_id = category_id;
                this.setState({
                    isLoading:true
                })
                setCategoryApi(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false,
                            selectedRowKeys:[],
                            selectRows:[]
                        })
                    })

            }
        })

    }

    patchDelete = () => {
        let ids,
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要删除的商品')
            return ;
        }
        ids = selectRows.map(item => {
            return item.id
        })
      this.delteCourse(ids,true)
    }





    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectRows: selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form

        return (
            <div className={'shadow-radius'}>
                <SetCourseCategory
                    onRef={ e => { this.ItemSetCategory = e } }
                    cateLists={ this.state.courseLists }
                ></SetCourseCategory>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'课程名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入课程名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'收费方式'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('charge_type', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>按课时</Select.Option>
                                            <Select.Option value={2}>按时段</Select.Option>
                                            <Select.Option value={3}>按期</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'课程类目'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('category_id', {
                                        initialValue: ""
                                    })(

                                        <TreeSelect
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder={'请选择'}
                                            treeData={this.state.courseLists}
                                        />

                                    )
                                }
                            </FormItem>

                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'课程状态'} className={' fl'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>出售中</Select.Option>
                                            <Select.Option value={2}>已售罄</Select.Option>
                                            <Select.Option value={3}>已下架</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'上课地点'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增课程</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.patchDelete}>批量删除</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOn}>批量上架</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOff}>批量下架</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchCates}>批量修改分类</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div> 
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }


}

export default Form.create({})(withRouter(CourseOffline))
