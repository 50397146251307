import React, { Component } from 'react'
import { Table, Pagination, Divider, Button, Modal ,Form,Input,Row, Col,Icon} from 'antd'
import { savePosition,getPosition,delePosition} from '@/api/educational'
import message from '@/utils/message'
const FormItem = Form.Item;

class TeacherPosition extends Component {
    state = {
        columns: [
            {
                title: '职位/职称名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p>{rowData.name}</p>
                }
            },
            {
                title: '排序',
                dataIndex: '2',
                sorter: true,
                defaultSortOrder: '',
                render: (text, rowData, index) => {
                    return (
                        <div>
                        {
                            rowData.sort_edit
                                ?
                                <Input
                                    style={{ width: '80px' }}
                                    value={rowData.sort}
                                    onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                    onBlur={(e) => { this.handleField(e, rowData, index) }}
                                    data-name={'sort'}
                                />
                                :
                                <span>{rowData.sort}</span>
                        }
                        <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />
                    </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '3',
                width:200,
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>

                            <Button type={"link"} size={"small"} onClick={()=>{this.handleEdit(rowData)}} >编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleDele(rowData.id)}}>删除</Button>

                        </div>
                    )
                }
            },

        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        is_show: false,
        confirmLoading:false,
        current_line:{},
        is_edit:false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        getPosition(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                        lists.forEach(item => {
                            item.sort_edit = 0;
                        })
                  
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = { ...rowData }
        sendData[name] = val;

        savePosition(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData

            lists[index]['sort_edit'] = 1
        this.setState({
            tableData: lists
        })
    }

    handleDele=id=>{
        
        Modal.confirm({
            title:`是否确认删除该职位/职称？`,
            onOk:() => {
                delePosition({id})
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
            }
        })
    }

    handleEdit=row=>{
        this.setState({
            current_line:row,
            is_show:true,
            is_edit:true
        })
    }


    handleOk = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let sendData=values
                this.setState({
                    confirmLoading:true
                })
                if(this.state.is_edit){
                   sendData.id=this.state.current_line.id
                }
                savePosition(sendData).then(res=>{
                    if(res.code==1){
                        message.success('操作成功')
                        .then(() => {
                            this.fetchData()
                        })
                        this.setState({
                            is_show:false,
                            edit:false,
                            current_line:""
                        })
                    }
                }).finally(()=>{
                    this.setState({
                        confirmLoading:false
                    })
                })
            }
        })
    }
    handleCancel = () => {
        this.setState({
            is_show: false,
            edit:false,
            current_line:""
        })
    }
    handleAdd=()=>{
        this.setState({
            is_show:true
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        let data = this.state.current_line||{}
        const formItemLayout = {
            labelCol:{
                span:7
            },
            wrapperCol:{
                span:17
            }
        }
        const { getFieldDecorator } = this.props.form
        return (
            <div className='pdb20'>

                <div className={'mgt10 mgb30'}>
                    <Button type={'primary'} icon={'plus'} onClick={this.handleAdd}>新增职位/职称</Button>
                </div>

                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.is_show ?
                        <Modal
                            title={ `${this.state.is_edit?"编辑":"新增"}职位/职称`}
                            visible={this.state.is_show}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            confirmLoading={this.state.confirmLoading}
                        >
                            <Form {...formItemLayout} className={'model-form'}>
                                <Row gutter={24}>
                                    <Col span={20}>
                                        <FormItem label="职位/职称名称">
                                            {getFieldDecorator('name', {
                                                initialValue: data.name||"",
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入职位/职称名称'
                                                    }
                                                ]
                                            })(<Input maxLength={20} />)}
                                        </FormItem>

                                        <FormItem label="排序号">
                                            {getFieldDecorator('sort', {
                                                initialValue: data.sort||0,
                                            })(<Input />)}
                                            <span className={`fi-help-text`}>排序号，越小越靠前</span>
                                        </FormItem>
                                    </Col>
                                    <Col span={4}>
                                    </Col>
                                </Row>
                            </Form>

                        </Modal> : null
                }
            </div>
        )
    }
}

export default   Form.create({})(TeacherPosition)

