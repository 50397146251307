import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Modal, Tag, InputNumber, Drawer, message } from 'antd'
import moment from 'moment'
import Loading from '@/components/Loading'
import CreatorAdd from './creatorAdd'
import { articleAuthor, articleAuthorDele } from '@/api/recommend.js'

class CreatorManage extends Component {
    state = {
        columns: [
            {
                title: '创作者',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.img} alt="" width={60} />
                            <div className='mgl10'>{rowData.name} {rowData.is_default ? '（商家）' : ''}</div>
                        </div>
                    )
                }
            },
            {
                title: '发布笔记数',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.article_num} </p>
                    )
                }
            },
            {
                title: '创作时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.created_at}</p>
                    )
                }
            },

            {
                title: '操作',
                dataIndex: '7',
                width: '250px',
                render: (text, rowData, index) => {

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={this.handleEdit(rowData)}>编辑</Button>
                            {rowData.is_default ? null : <>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleDele([rowData.id],false)}}>删除</Button>
                            </>}
                        </div>
                    )
                }
            }
        ],
        isLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        selectedRowKeys: [],
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        this.setState({
            isLoading: true
        })

        articleAuthor(sendData).then(res => {
            if(res.code==1){
                let {current_page,per_page ,total,data}=res.data.list
                this.setState({
                    page:current_page,
                    total,
                    per_page,
                    tableData:data
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    

    handleAdd = () => {
        let creatorData = ''
        this.CreatorAdd.show({
            creatorData,
            callBack: () => {
                this.fetchData()
            }
        })
    }
    handleEdit=row=>{
        
        return ()=>{
            this.CreatorAdd.show({
                creatorData:row,
                callBack: () => {
                    this.fetchData()
                }
            })
        }
    }
    handleDele=(author_ids,is_batch)=>{
        Modal.confirm({
            title:`确定要删除${is_batch?'这些':'该'}创作者吗？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                articleAuthorDele({author_ids})
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false,
                            selectedRowKeys:[]
                        })
                    })
            }
        })
    }
    batchDele=()=>{
        let selectedRowKeys=this.state.selectedRowKeys
       this.handleDele(selectedRowKeys,true)

    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    render() {

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            },
            getCheckboxProps: record => ({
                disabled: Boolean(record.is_default) 
            }),
        }

        return (
            <div>
                <div className={'mgt10'}>
                    <Button type={'primary'} icon={'plus'} onClick={this.handleAdd}>新增创作者</Button>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={false}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.batchDele}>批量删除</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                <CreatorAdd onRef={e => this.CreatorAdd = e} />
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}


export default withRouter(CreatorManage)
