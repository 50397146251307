import React, { Component } from 'react'
import { Form, Input, Button, Select, Pagination,Table,Divider,Tag,Modal} from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { physicalForm,physicalFormSetStatus,physicalFormDelete,physicalFormCopy } from '@/api/educational'
import message from '@/utils/message'
const FormItem = Form.Item;

class bmiLists extends Component {

    state = {
        selectRows: [],
        selectedRowKeys:[],
        columns: [
            {
                title: '体测表名称',
                dataIndex: '1',
                
                render: (text, rowData, index) => {
                    return (
                        <div >{rowData.name}</div>
                    )
                }
            },
            {
                title: '测试项目数量（项）',
                dataIndex: '2',
            
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.item_num  }</div>
                    )
                }
            },
            {
                title: '已填表学员',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {rowData.user_physical_form_count}
                        </div>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '4',
                render: (text, rowData, index) => {
                  
                    return (
                        <div>
                           {rowData.created_at}
                        </div>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <Tag color={rowData.status? 'green':'red'}>{rowData.status? '启用':'停用'}</Tag>
                    )
                }
            },
          
            {
                title: '操作',
                dataIndex: '8',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleEdit(rowData.id)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                             {
                                rowData.status?
                                <Button type={"link"} size={"small"} onClick={()=>{this.patchOff([rowData.id])}}>停用</Button>:
                                <Button type={"link"} size={"small"} onClick={()=>{this.patchOn([rowData.id])}}>启用</Button>
                             }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleAddCopy(rowData.id)}}>复制</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.patchDelete([rowData.id])}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        searchForm:{
            name:"",
        },
        tableLoading:false,
        page:1,
        per_page:10,
        total:0,
        poster:"",
        path:""

    }
    componentDidMount() {
       this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData=this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        physicalForm(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                  
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

  

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }
    
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name:"",
        }
     
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleEdit=id=>{
        this.props.history.push({
            pathname:`/educational/bmi-edit/`+id
        })
        
    }

    handleAdd=()=>{
        this.props.history.push({
            pathname:`/educational/bmi-edit/`
        })
    }

    handleAddCopy=id=>{
        physicalFormCopy({ id }).then(res => {
            if (res.code == 1) {
                this.fetchData()
                message.success(res.msg)
            }
        })
    }


    patchDelete=(ids)=>{
        if(!ids.length){return}
        Modal.confirm({
            title: `是否确认删除？`,
            onOk: () => {
                physicalFormDelete({ ids }).then(res => {
                    if (res.code == 1) {
                        this.fetchData()
                        message.success(res.msg)
                    }
                }).finally(() => {
                    this.setState({
                        selectedRowKeys:[],
                        selectRows:[]
                    })
                })
            }
        })
    }
    patchOff=(ids)=>{
        if(!ids.length){return}
        Modal.confirm({
            title: `是否确认停用？`,
            onOk: () => {
                physicalFormSetStatus({ ids,status:0 }).then(res => {
                    if (res.code == 1) {
                        this.fetchData()
                        message.success(res.msg)
                    }
                }).finally(() => {
                    this.setState({
                        selectedRowKeys:[],
                        selectRows:[]
                    })
                })
            }
        })
    }
    patchOn=(ids)=>{
        if(!ids.length){return}
        Modal.confirm({
            title: `是否确认启用？`,
            onOk: () => {
                physicalFormSetStatus({ ids,status:1 }).then(res => {
                    if (res.code == 1) {
                        this.fetchData()
                        message.success(res.msg)
                    }
                }).finally(() => {
                    this.setState({
                        selectedRowKeys:[],
                        selectRows:[]
                    })
                })
            }
        })
    }


    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectRows: selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        let selectedRowKeys=this.state.selectedRowKeys || [];
      
        return (
          <div>
              <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'体测表名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入体测表单名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem className={'fl mgl70'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>

                        </div>
                        
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增体测表</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={()=>{this.patchDelete(selectedRowKeys)}}>批量删除</Button>
                        <Button className={'btn mgl10'} type={""} onClick={()=>{this.patchOff(selectedRowKeys)}}>批量停用</Button>
                        <Button className={'btn mgl10'} type={""} onClick={()=>{this.patchOn(selectedRowKeys)}}>批量启用</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

          </div>
        )
    }
}

export default Form.create({})(withRouter(bmiLists))