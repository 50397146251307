import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, TreeSelect, Tooltip, Icon, Tag, Drawer, Divider } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { itemCates } from '@/api/item'
import { skuItemLists } from '@/api/item'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import StorageDetail from "./components/StorageDetail";
const FormItem = Form.Item;

class Stock extends React.Component{
    state = {
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.item_id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }

            },
            {
                title:'规格',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return(
                        <div>
                            {
                                rowData.sku_names
                                    ?
                                    <Tag>
                                        {
                                            rowData.sku_names.map((item,index) => (
                                                <span className={`lh25`} key={index}>{item}
                                                    {
                                                        index >= rowData.sku_names.length - 1
                                                            ?
                                                            null
                                                            :
                                                            '/'
                                                    }
                                                </span>
                                            ))
                                        }
                                    </Tag>

                                    :
                                    '--'
                            }
                        </div>
                    )
                }
            },
            {
                title:'sku编码',
                dataIndex:'logo',
                render:(text, rowData, index) => {
                    return (
                        <div>{rowData.goods_sku_code || `--`}</div>
                    )
                }
            },
            {
                title:'商品单位',
                dataIndex:'unit',
                render:(text, rowData, index) => {
                    return(
                        <div>{rowData.unit_name || `件`}</div>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            可用库存
                            <Tooltip placement="topLeft" title={`可供使用的库存，包括售卖、调拨等，可用库存 = 实物库存数量 - 待出库`}>
                                <Icon type="question-circle"  className={`mgl5`} />
                            </Tooltip>
                        </div>
                    )
                },
                dataIndex:'stock3',
                render:(text, rowData, index) => {
                    return(
                        <div>
                            {
                                rowData.stock
                                    ?
                                    <span className={`lh25`}>{rowData.stock}</span>
                                    :
                                    <span className={`colorRed`}>0</span>
                            }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleStorageDetail(rowData)}}>库存分布</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"}  onClick={() => {this.handleDesc(rowData)}}>出入库明细</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            store_id:'',
            name:'',
            goods_code:'',
            category_id:'',
        },
        tableData:[],
        cateLists:[],
        storeLists:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        drawerVisible:false,
        currentRow:null

    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
        this.fetchCate()
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        itemCates(sendData)
            .then(res => {
                let lists = res.data.list.data,
                    defaults = [ { name:'全部',id:0 } ];
                lists = defaults.concat(lists);
                lists.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })
                this.setState({
                    cateLists:lists
                })

            })
            .finally(res => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading:true
        })
        skuItemLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.goods_list.data,
                        total = res.data.goods_list.total,
                        per_page = res.data.goods_list.per_page;
                    tableData.forEach((item,index) => {
                        item.item_id = item.id;
                        item.id = `${item.id}-${item.sku_id}`
                        if(item.alias){
                            let sku_names = [];
                            for(var i in item.alias){
                                sku_names.push(item.alias[i])
                            }
                            item.sku_names = sku_names
                        }

                    })
                    this.setState({
                        tableData, per_page, total
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleStorageDetail = rowData => {
        this.setState({
            drawerVisible:true,
            currentRow :rowData
        })
    }
    handleDesc = rowData => {
        this.props.history.push({
            pathname:`/stock/detail/${rowData.goods_sku_code ? rowData.goods_sku_code : rowData.goods_code}`
        })
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
    }
    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={`content-msg mgb10`}>
                    <p>
                        仅普通商品才可设置库存，服务项目默认无限库存。
                    </p>
                </div>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form '}>
                        <div className={`clearfix`}>
                            <FormItem label={'仓库/门店'} className={'fl'}>
                                {
                                    getFieldDecorator('store_id',{
                                        initialValue:0
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'商品名称'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称'/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'商品编码'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('goods_code',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品编码'/>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'商品分类'} className={'fl'}>
                                {
                                    getFieldDecorator('category_id',{
                                        initialValue: 0
                                    })(
                                        <TreeSelect
                                            treeData={ this.state.cateLists }
                                            treeCheckable={false}
                                            style={{width:'170px'}}
                                            placeholder={'请选择'}
                                        />
                                    )
                                }
                            </FormItem>
                            <FormItem className={'mgl20 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>

                        </div>



                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.isLoading ? <Loading /> : null
                }
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`库存分布`}
                            closable={true}
                            width={`60%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={ this.handleDrawerClose }
                            headerStyle={{borderBottom:'0px'}}
                            bodyStyle={{padding:'0px'}}
                        >
                            <StorageDetail
                                onRef={e => { this.StorageDetail = e }}
                                goods_id={ this.state.currentRow ? this.state.currentRow.item_id : '' }
                                sku_id = { this.state.currentRow ? this.state.currentRow.sku_id : '' }
                            ></StorageDetail>
                        </Drawer>
                        :
                        null
                }
            </div>
        )
    }

}

export default Form.create({})(withRouter(Stock))
