import React, { Component } from 'react'
import { Table, Pagination, Button, Divider, Form ,Select,Tag,message ,Modal} from 'antd'
import { studentCourseTable } from '@/api/student'
import {  gradeSign } from '@/api/educational'
const FormItem = Form.Item;
const { Option } = Select;

class TimeTableTab extends Component {
    state = {
        columns: [
            {
                title:<span className='mgl16'>上课时间</span> ,
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p className='mgl16'>{`${rowData.table_date} ${rowData.week_day} ${rowData.start_time}-${rowData.end_time}`}</p>
                }
            },
            {
                title: '班级',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.grade_name}</p>
                }
            },
            {
                title: '上课地点',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return <p>{rowData.store_name||"-"}</p>
                }
            },
            {
                title: '上课老师/助教',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{rowData.teacher_name}{rowData.assist_teacher_name?"/":""}{rowData.assist_teacher_name||""}</p>
                }
            },
            {
                title: '签到状态',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    let tag
                    switch (rowData.status) {
                        case 0:
                            tag = <Tag color="red">待签到</Tag>
                            break;
                        case 1:
                            tag = <Tag color="green">已签到</Tag>
                            break;
                        case 2:
                            tag = <Tag color="gold">已请假</Tag>
                            break;
                    }
                    return <p>{tag}</p>
                }
            },
            {
                title: '消耗课时',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return <p>{rowData.course_cost_num||"-"}</p>
                }
            },
            {
                title: '签到时间',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return <p>{rowData.created_at||"-"}</p>
                }
            },
            {
                title: '操作',
                dataIndex: '8',
                render: (text, rowData, index) => {

                    if(rowData.status === 0){
                        return (
                            <div>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleSignIn(rowData)}}>签到</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleLeave(rowData)}}>请假</Button>
                            </div>
                        )
                    }else{
                        return <div>-</div>
                    }
                }
            }
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        status:0
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.student_id = this.props.student_id
        sendData.page = this.state.page
        sendData.per_page = this.state.per_page
        sendData.status=this.state.status
        this.setState({
            tableLoading: true
        })
        studentCourseTable(sendData).then(res => {

            if (res.code == 1) {
                let list = res.data.list
                let total = list.total,
                    per_page = list.per_page,
                    tableData = list.data
                this.setState({
                    total, per_page, tableData
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    onSelect=val=>{
      
      this.setState({
        status:val
      })
    }

    handleSignIn = row => {
        Modal.confirm({
            title: `确认该学员已来上课？确定后将扣除相应课时`,
            onOk: () => {
                this.handleSign(row.id, 1)
            },
        });
    }
    handleLeave = row => {
        Modal.confirm({
            title: ` 确定该学员已请假？确认后不扣课时。`,
            onOk: () => {
                this.handleSign(row.id, 2)
            }
        });
    }

    handleSign = (id, type) => {
        let sendData = {}
        sendData.course_table_id = id
        sendData.type = type
        sendData.student_ids = [this.props.student_id]
      
        gradeSign(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                this.fetchData()
            }
        })
    }

    search=()=>{
        this.setState({
            page:1
        },()=>{
            this.fetchData()
        })
    }


    render() {
        return (
            <div className='pdb20'>
                <div>
                    <FormItem label={'签到状态'} className={'mgl24 flex'} >
                        <Select value={this.state.status} style={{ width: 120 }} onChange={this.onSelect} >
                            <Option value={0}>全部</Option>
                            <Option value={1}>待签到</Option>
                            <Option value={2}>已签到</Option>
                            <Option value={3}>请假</Option>
                        </Select>
                        <Button type={`primary mgl20`} onClick={this.search}>查询</Button>
                    </FormItem>
                </div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showSizeChanger pageSizeOptions={[this.state.per_page?String(this.state.per_page):'20']}  current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default TimeTableTab
